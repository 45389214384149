import { IAddInsuranceFormValues } from './types';
import { MySelectInputOptions } from '../../../components/FormControls/MySelectInput/types';
import * as Yup from 'yup';
import { VehicleInsuranceFormStepE, VehicleTypeE } from './vehicleInsuranceEnum';

export const defaultAddInsuranceFormValues: IAddInsuranceFormValues = {
  vehiclePlatesNumber: '',
  vehicleFirstRegistrationDate: undefined,
  vehicleVin: '',
  wasVehicleCheckedInGovPage: false,
  policyType: [],
  policyStartDate: '',
  oldPolicyEndDate: '',
  otherDataSectionVehiclePurpose: 'Użytek własny',
  otherDataSectionVehicleIsLeased: false,
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  pesel: '',
  ownerSectionVehicleRegisterTo: 'osobę fizyczną',
  ownerSectionIsPolicyholder: true,
  ownerSectionIsOwnerOfVehicle: true,
  ownerSectionIsPrimaryVehicleUser: true,
  ownerSectionPostCode: '',
  ownerSectionCity: '',
  ownerSectionStreet: '',
  ownerSectionHouseNumber: '',
  ownerSectionFlatNumber: '',
  leasedSectionCity: '',

  // Placeholder values for other properties
  vehicleType: '',
  vehicleBrand: '',
  vehicleProductionYear: '',
  vehiclePurchaseYear: '',
  vehicleModel: '',
  vehicleModelGov: '',
  vehicleFuelType: '',
  vehicleEngineCapacity: '',
  vehicleEnginePower: '',
  vehicleGearboxType: '',
  vehicleEquipmentType: '',
  vehicleModelAutoExpert: '',
  vehicleVersionAutoExpert: '',
  vehicleNumberOfSeats: 0,
  vehicleCurrentMileage: 0,
  vehicleApproximateValue: 0,

  otherDataSectionVehicleEstimatedAnnualMileage: '',
  otherDataSectionParkingPlaceAtNight: '',
  otherDataSectionVehicleEstimatedUsedAbroad: '',
  otherDataSectionVehicleWasImported: undefined,
  otherDataSectionVehicleSteeringWheelOnRightSide: undefined,
  otherDataSectionVehicleRegisteredAsTruck: undefined,
  // oc
  ocSectionLastInsuredByCurrentOwner: undefined,
  ocSectionPrevInsuredCompany: '',
  ocSectionPrevOwnerHadOc: undefined,
  ocSectionPrevOwnerOcInsuredCompany: '',
  // ac
  acSectionLastInsuredByCurrentOwner: undefined,
  acSectionPrevInsuredCompany: '',
  acSectionPrevOwnerHadAc: undefined,
  acSectionPrevOwnerAcInsuredCompany: '',

  leasedSectionIsPolicyholder: false,
  leasedSectionNip: '',
  leasedSectionRegon: '',
  leasedSectionFullName: '',
  leasedSectionPkd: '',
  leasedSectionPostCode: '',
  leasedSectionPhoneNumber: '',
  leasedSectionEmail: '',
  additionalUserSectionIsAnotherVehicleUser: false,
  additionalUserSectionBornDate: undefined,
  additionalUserSectionObtainingDrivingLicenseDate: undefined,
};

export const addVehicleInsuranceFormSchema = Yup.object().shape({
  vehicleFirstRegistrationDate: Yup.date()
    .transform((value, originalValue) => {
      // If the input is an empty string, convert it to null
      return originalValue === '' ? null : value;
    })
    .nullable()
    .required('Data pierwszej rejestracji jest wymagana'),
  vehicleVin: Yup.string().required('Pole jest wymagane'),
  vehiclePlatesNumber: Yup.string().required('Pole jest wymagane'),
  wasVehicleCheckedInGovPage: Yup.boolean(),

  pesel: Yup.string()
    .matches(/^\d{11}$/, 'PESEL musi składać się z 11 cyfr')
    .required('Pole jest wymagane'),
  firstName: Yup.string().required('Pole jest wymagane'),
  lastName: Yup.string().required('Pole jest wymagane'),
  phone: Yup.string()
    .matches(/^\d{9}$/, 'Numer telefonu musi składać się z 9 cyfr')
    .required('Pole jest wymagane'),
  email: Yup.string().email('Nieprawidłowy adres e-mail').required('Pole jest wymagane'),
  ownerSectionVehicleRegisterTo: Yup.string().required('Pole jest wymagane'),
  ownerSectionIsPolicyholder: Yup.boolean().required('Pole jest wymagane'),
  ownerSectionIsOwnerOfVehicle: Yup.boolean().required('Pole jest wymagane'),
  ownerSectionIsPrimaryVehicleUser: Yup.boolean().required('Pole jest wymagane'),
  ownerSectionPostCode: Yup.string()
    .matches(/^\d{2}-\d{3}$/, 'Kod pocztowy musi być w formacie XX-XXX')
    .required('Pole jest wymagane'),
  ownerSectionCity: Yup.string().required('Pole jest wymagane'),
  ownerSectionStreet: Yup.string().required('Pole jest wymagane'),
  ownerSectionHouseNumber: Yup.string().required('Pole jest wymagane'),

  policyStartDate: Yup.string()
    .required('Pole jest wymagane')
    .test('is-today-or-future', 'Data musi być dzisiejsza lub późniejsza o co najmniej 7 dni', (value) => {
      const today = new Date();
      const futureDate = new Date(today);
      futureDate.setDate(today.getDate() + 7);
      const formattedFutureDate = futureDate.toISOString().split('T')[0];
      if (!value) return false;
      return value >= formattedFutureDate;
    }),
  policyType: Yup.array()
    .of(Yup.string())
    .min(1, 'Pole jest wymagane') // Ensures at least one item is present
    .required('Pole jest wymagane') // Ensures the field itself is not empty or null
    .test('is-not-empty', 'Pole jest wymagane', (value) => !!value && value.length > 0),
  vehicleType: Yup.string().required('Pole jest wymagane'),
  vehicleBrand: Yup.string().required('Pole jest wymagane'),
  vehicleProductionYear: Yup.string().required('Pole jest wymagane'),
  vehiclePurchaseYear: Yup.string().required('Pole jest wymagane'),
  vehicleModel: Yup.string().required('Pole jest wymagane'),
  vehicleModelGov: Yup.string().required('Pole jest wymagane'),
  vehicleFuelType: Yup.string().required('Pole jest wymagane'),
  vehicleEngineCapacity: Yup.string().required('Pole jest wymagane'),
  vehicleEnginePower: Yup.string().required('Pole jest wymagane'),
  vehicleGearboxType: Yup.string().required('Pole jest wymagane'),
  vehicleEquipmentType: Yup.string().required('Pole jest wymagane'),
  // vehicleModelAutoExpert: Yup.string().required('Pole jest wymagane'),
  // vehicleVersionAutoExpert: Yup.string().required('Pole jest wymagane'),
  vehicleNumberOfSeats: Yup.number().when('vehicleType', {
    is: (vehicleType: string) => vehicleType === VehicleTypeE.CAR,
    then: Yup.number().min(1, 'Minimalna wartość to 1').required('Pole jest wymagane'),
  }),
  vehicleCurrentMileage: Yup.number().min(1, 'Minimalna wartość to 1').required('Pole jest wymagane'),
  vehicleApproximateValue: Yup.number().when('policyType', {
    is: (policyType: string[]) => policyType.some((type) => type.toLowerCase() === 'ac'),
    then: Yup.number().min(1, 'Minimalna wartość to 1').required('Pole jest wymagane'),
  }),

  // Other Data
  otherDataSectionVehicleEstimatedAnnualMileage: Yup.string().required('Pole jest wymagane'),
  otherDataSectionParkingPlaceAtNight: Yup.string().required('Pole jest wymagane'),
  otherDataSectionVehicleEstimatedUsedAbroad: Yup.string().required('Pole jest wymagane'),
  otherDataSectionVehiclePurpose: Yup.string().required('Pole jest wymagane'),
  otherDataSectionVehicleWasImported: Yup.boolean().required('Pole jest wymagane'),
  otherDataSectionVehicleSteeringWheelOnRightSide: Yup.boolean().when(
    ['vehicleType', 'otherDataSectionVehicleWasImported'],
    {
      is: (vehicleType: VehicleTypeE, otherDataSectionVehicleWasImported: boolean) =>
        vehicleType === VehicleTypeE.CAR && otherDataSectionVehicleWasImported,
      then: Yup.boolean().required('Pole jest wymagane'),
    },
  ),
  otherDataSectionVehicleRegisteredAsTruck: Yup.boolean().when('vehicleType', {
    is: (vehicleType: string) => vehicleType === VehicleTypeE.CAR,
    then: Yup.boolean().required('Pole jest wymagane'),
  }),
  otherDataSectionVehicleIsLeased: Yup.boolean().required('Pole jest wymagane'),

  // OC Insurance History
  ocSectionLastInsuredByCurrentOwner: Yup.boolean().when('policyType', {
    is: (policyType: string[]) => policyType.includes('OC'),
    then: (schema) => schema.required('Pole jest wymagane'),
  }),

  ocSectionPrevInsuredCompany: Yup.string().when(['ocSectionLastInsuredByCurrentOwner', 'policyType'], {
    is: (lastInsured: boolean, policyType: string[]) =>
      lastInsured && Array.isArray(policyType) && policyType.includes('OC'),
    then: (schema) => schema.required('Pole jest wymagane'),
  }),

  ocSectionPrevOwnerHadOc: Yup.boolean().when(['ocSectionLastInsuredByCurrentOwner', 'policyType'], {
    is: (lastInsured: boolean, policyType: string[]) =>
      !lastInsured && Array.isArray(policyType) && policyType.includes('OC'),
    then: (schema) => schema.required('Pole jest wymagane'),
  }),

  ocSectionPrevOwnerOcInsuredCompany: Yup.string().when(['ocSectionPrevOwnerHadOc', 'policyType'], {
    is: (prevOwnerHadOc: boolean, policyType: string[]) =>
      prevOwnerHadOc && Array.isArray(policyType) && policyType.includes('OC'),
    then: (schema) => schema.required('Pole jest wymagane'),
  }),

  // AC Insurance History
  acSectionLastInsuredByCurrentOwner: Yup.boolean().when('policyType', {
    is: (policyType: string[]) => {
      console.log('policyType', policyType);
      return policyType.includes('AC');
    },
    then: (schema) => schema.required('Pole jest wymagane'),
  }),

  acSectionPrevInsuredCompany: Yup.string().when(['acSectionLastInsuredByCurrentOwner', 'policyType'], {
    is: (lastInsured: boolean, policyType: string[]) =>
      lastInsured && Array.isArray(policyType) && policyType.includes('AC'),
    then: (schema) => schema.required('Pole jest wymagane'),
  }),

  acSectionPrevOwnerHadAc: Yup.boolean().when(['acSectionLastInsuredByCurrentOwner', 'policyType'], {
    is: (lastInsured: boolean, policyType: string[]) =>
      !lastInsured && Array.isArray(policyType) && policyType.includes('AC'),
    then: (schema) => schema.required('Pole jest wymagane'),
  }),

  acSectionPrevOwnerAcInsuredCompany: Yup.string().when(['acSectionPrevOwnerHadAc', 'policyType'], {
    is: (prevOwnerHadOc: boolean, policyType: string[]) => {
      return prevOwnerHadOc && Array.isArray(policyType) && policyType.includes('AC');
    },
    then: (schema) => schema.required('Pole jest wymagane'),
  }),

  // Leasing Section (only if isVehicleLeased = "Tak")
  leasedSectionIsPolicyholder: Yup.boolean(),
  leasedSectionNip: Yup.string().when('isVehicleLeased', {
    is: true,
    then: (schema) => schema.required('Pole jest wymagane'),
  }),
  leasedSectionRegon: Yup.string().when('isVehicleLeased', {
    is: true,
    then: (schema) => schema.required('Pole jest wymagane'),
  }),
  leasedSectionFullName: Yup.string().when('isVehicleLeased', {
    is: true,
    then: (schema) => schema.required('Pole jest wymagane'),
  }),
  leasedSectionPkd: Yup.string().when('isVehicleLeased', {
    is: true,
    then: (schema) => schema.required('Pole jest wymagane'),
  }),
  leasedSectionPostCode: Yup.string().when('isVehicleLeased', {
    is: true,
    then: (schema) =>
      schema.matches(/^\d{2}-\d{3}$/, 'Kod pocztowy musi być w formacie XX-XXX').required('Pole jest wymagane'),
  }),
  leasedSectionPhoneNumber: Yup.string().when('isVehicleLeased', {
    is: true,
    then: (schema) =>
      schema.matches(/^\d{9}$/, 'Numer telefonu musi składać się z 9 cyfr').required('Pole jest wymagane'),
  }),
  leasedSectionEmail: Yup.string().when('isVehicleLeased', {
    is: true,
    then: (schema) => schema.email('Nieprawidłowy adres e-mail').required('Pole jest wymagane'),
  }),

  // Additional Vehicle User
  additionalUserSectionIsAnotherVehicleUser: Yup.string().required('Pole jest wymagane'),
  additionalUserSectionBornDate: Yup.string().when('additionalUserSectionIsAnotherVehicleUser', {
    is: 'Tak',
    then: (schema) => schema.required('Pole jest wymagane'),
  }),
  additionalUserSectionObtainingDrivingLicenseDate: Yup.string().when('additionalUserSectionIsAnotherVehicleUser', {
    is: 'Tak',
    then: (schema) => schema.required('Pole jest wymagane'),
  }),
});

export const insuranceCompaniesOptions: MySelectInputOptions[] = [
  { value: 'AGRO TUW', label: 'AGRO TUW' },
  { value: 'Allianz', label: 'Allianz' },
  { value: 'Balcia', label: 'Balcia' },
  { value: 'Beesafe', label: 'Beesafe' },
  { value: 'Benefia', label: 'Benefia' },
  { value: 'Compensa', label: 'Compensa' },
  { value: 'Euroins', label: 'Euroins' },
  { value: 'Generali', label: 'Generali' },
  { value: 'Hestia', label: 'Hestia' },
  { value: 'Inne', label: 'Inne' },
  { value: 'InterRisk', label: 'InterRisk' },
  { value: 'Link4', label: 'Link4' },
  { value: 'MTU', label: 'MTU' },
  { value: 'MTU24', label: 'MTU24' },
  { value: 'Proama', label: 'Proama' },
  { value: 'PZU', label: 'PZU' },
  { value: 'Trasti', label: 'Trasti' },
  { value: 'TUW', label: 'TUW' },
  { value: 'TUZ', label: 'TUZ' },
  { value: 'Uniqa', label: 'Uniqa' },
  { value: 'Warta/HDI', label: 'Warta/HDI' },
  { value: 'Wiener (Gothaer)', label: 'Wiener (Gothaer)' },
  { value: 'YouCanDrive', label: 'YouCanDrive' },
];

export const vehicleOcCommonSteps = [
  VehicleInsuranceFormStepE.BASIC_INFO_3_VALUES,
  VehicleInsuranceFormStepE.FIRST_REGISTRATION_DATE,
  VehicleInsuranceFormStepE.VIN,
  VehicleInsuranceFormStepE.POLICY_TYPE,
  VehicleInsuranceFormStepE.ESTIMATED_ANNUAL_MILEAGE,
  VehicleInsuranceFormStepE.PARKING_PLACE,
  VehicleInsuranceFormStepE.ESTIMATED_USED_ABROAD,
  VehicleInsuranceFormStepE.WAS_VEHICLE_IMPORTED,
  VehicleInsuranceFormStepE.IS_STEERING_WHEEL_ON_RIGHT,
  VehicleInsuranceFormStepE.IS_REGISTERED_AS_TRUCK,
  VehicleInsuranceFormStepE.POLICY_START_DATE,
  VehicleInsuranceFormStepE.VEHICLE_TYPE,
];

export const vehicleOcCarSteps = [
  // Vehicle details (populated from GOV data or user input)
  VehicleInsuranceFormStepE.VEHICLE_BRAND,
  VehicleInsuranceFormStepE.VEHICLE_PRODUCTION_YEAR,
  VehicleInsuranceFormStepE.VEHICLE_PURCHASE_YEAR,
  VehicleInsuranceFormStepE.VEHICLE_MODEL,
  VehicleInsuranceFormStepE.VEHICLE_FUEL_TYPE,
  VehicleInsuranceFormStepE.VEHICLE_ENGINE_CAPACITY,
  VehicleInsuranceFormStepE.VEHICLE_ENGINE_POWER,
  VehicleInsuranceFormStepE.VEHICLE_GEARBOX_TYPE,
  VehicleInsuranceFormStepE.VEHICLE_SECOND_TYPE,
  // VehicleInsuranceFormStepE.VEHICLE_MODEL_AUTO_EXPERT,
  // VehicleInsuranceFormStepE.VEHICLE_VERSION_AUTO_EXPERT,
  VehicleInsuranceFormStepE.VEHICLE_NUMBER_OF_SEATS,
  VehicleInsuranceFormStepE.VEHICLE_CURRENT_MILEAGE,
  VehicleInsuranceFormStepE.VEHICLE_APPROXIMATE_VALUE,

  VehicleInsuranceFormStepE.LAST_INSURED_BY_CURRENT_OWNER,
  VehicleInsuranceFormStepE.PREV_INSURED_COMPANY,
  VehicleInsuranceFormStepE.PREV_OWNER_HAD_OC,
  VehicleInsuranceFormStepE.PREV_OWNER_OC_INSURED_COMPANY,

  VehicleInsuranceFormStepE.AC_LAST_INSURED_BY_CURRENT_OWNER,
  VehicleInsuranceFormStepE.AC_PREV_INSURED_COMPANY,
  VehicleInsuranceFormStepE.AC_PREV_OWNER_HAD_AC,
  VehicleInsuranceFormStepE.AC_PREV_OWNER_AC_INSURED_COMPANY,

  VehicleInsuranceFormStepE.OWNER_PESEL,
  VehicleInsuranceFormStepE.OWNER_FIRST_NAME,
  VehicleInsuranceFormStepE.OWNER_LAST_NAME,
  VehicleInsuranceFormStepE.OWNER_EMAIL,
  VehicleInsuranceFormStepE.OWNER_PHONE_NUMBER,
  VehicleInsuranceFormStepE.OWNER_POST_CODE,
  VehicleInsuranceFormStepE.SUMMARY,
];

export const vehicleOcMotorcycleSteps = [
  VehicleInsuranceFormStepE.VEHICLE_BRAND,
  VehicleInsuranceFormStepE.VEHICLE_PRODUCTION_YEAR,
  VehicleInsuranceFormStepE.VEHICLE_PURCHASE_YEAR,
  VehicleInsuranceFormStepE.VEHICLE_MODEL,
  VehicleInsuranceFormStepE.VEHICLE_FUEL_TYPE,
  VehicleInsuranceFormStepE.VEHICLE_ENGINE_CAPACITY,
  VehicleInsuranceFormStepE.VEHICLE_ENGINE_POWER,
  VehicleInsuranceFormStepE.VEHICLE_GEARBOX_TYPE,
  VehicleInsuranceFormStepE.VEHICLE_SECOND_TYPE,
  // VehicleInsuranceFormStepE.VEHICLE_MODEL_AUTO_EXPERT,
  // VehicleInsuranceFormStepE.VEHICLE_VERSION_AUTO_EXPERT,
  VehicleInsuranceFormStepE.VEHICLE_NUMBER_OF_SEATS,
  VehicleInsuranceFormStepE.VEHICLE_CURRENT_MILEAGE,
  VehicleInsuranceFormStepE.VEHICLE_APPROXIMATE_VALUE,

  VehicleInsuranceFormStepE.LAST_INSURED_BY_CURRENT_OWNER,
  VehicleInsuranceFormStepE.PREV_INSURED_COMPANY,
  VehicleInsuranceFormStepE.PREV_OWNER_HAD_OC,
  VehicleInsuranceFormStepE.PREV_OWNER_OC_INSURED_COMPANY,

  VehicleInsuranceFormStepE.AC_LAST_INSURED_BY_CURRENT_OWNER,
  VehicleInsuranceFormStepE.AC_PREV_INSURED_COMPANY,
  VehicleInsuranceFormStepE.AC_PREV_OWNER_HAD_AC,
  VehicleInsuranceFormStepE.AC_PREV_OWNER_AC_INSURED_COMPANY,

  VehicleInsuranceFormStepE.OWNER_PESEL,
  VehicleInsuranceFormStepE.OWNER_FIRST_NAME,
  VehicleInsuranceFormStepE.OWNER_LAST_NAME,
  VehicleInsuranceFormStepE.OWNER_EMAIL,
  VehicleInsuranceFormStepE.OWNER_PHONE_NUMBER,
  VehicleInsuranceFormStepE.OWNER_POST_CODE,
  VehicleInsuranceFormStepE.SUMMARY,
];

export const vehicleOcTruckSteps = [
  VehicleInsuranceFormStepE.OWNER_PESEL,
  VehicleInsuranceFormStepE.OWNER_EMAIL,
  VehicleInsuranceFormStepE.OWNER_PHONE_NUMBER,
  VehicleInsuranceFormStepE.OWNER_POST_CODE,
];

export const vehicleOCStepFields: Record<VehicleInsuranceFormStepE, (keyof IAddInsuranceFormValues)[]> = {
  // Basic information (GOV lookup step)
  [VehicleInsuranceFormStepE.BASIC_INFO_3_VALUES]: ['vehiclePlatesNumber', 'pesel'],
  [VehicleInsuranceFormStepE.FIRST_REGISTRATION_DATE]: ['vehicleFirstRegistrationDate'],
  [VehicleInsuranceFormStepE.VIN]: ['vehicleVin'],

  [VehicleInsuranceFormStepE.POLICY_TYPE]: ['policyType'],

  // Insurance policy information
  [VehicleInsuranceFormStepE.POLICY_START_DATE]: ['policyStartDate'],

  // Vehicle details (populated from GOV data or user input)
  [VehicleInsuranceFormStepE.VEHICLE_TYPE]: ['vehicleType'],
  [VehicleInsuranceFormStepE.VEHICLE_BRAND]: ['vehicleBrand'],
  [VehicleInsuranceFormStepE.VEHICLE_PRODUCTION_YEAR]: ['vehicleProductionYear'],
  [VehicleInsuranceFormStepE.VEHICLE_PURCHASE_YEAR]: ['vehiclePurchaseYear'],
  [VehicleInsuranceFormStepE.VEHICLE_MODEL]: ['vehicleModel'],
  [VehicleInsuranceFormStepE.VEHICLE_FUEL_TYPE]: ['vehicleFuelType'],
  [VehicleInsuranceFormStepE.VEHICLE_ENGINE_CAPACITY]: ['vehicleEngineCapacity'],
  [VehicleInsuranceFormStepE.VEHICLE_ENGINE_POWER]: ['vehicleEnginePower'],
  [VehicleInsuranceFormStepE.VEHICLE_GEARBOX_TYPE]: ['vehicleGearboxType'],
  [VehicleInsuranceFormStepE.VEHICLE_SECOND_TYPE]: ['vehicleEquipmentType'],
  [VehicleInsuranceFormStepE.VEHICLE_MODEL_AUTO_EXPERT]: ['vehicleModelAutoExpert'],
  [VehicleInsuranceFormStepE.VEHICLE_VERSION_AUTO_EXPERT]: ['vehicleVersionAutoExpert'],
  [VehicleInsuranceFormStepE.VEHICLE_NUMBER_OF_SEATS]: ['vehicleNumberOfSeats'],
  [VehicleInsuranceFormStepE.VEHICLE_CURRENT_MILEAGE]: ['vehicleCurrentMileage'],
  [VehicleInsuranceFormStepE.VEHICLE_APPROXIMATE_VALUE]: ['vehicleApproximateValue'],

  // Additional vehicle details
  [VehicleInsuranceFormStepE.ESTIMATED_ANNUAL_MILEAGE]: ['otherDataSectionVehicleEstimatedAnnualMileage'],
  [VehicleInsuranceFormStepE.PARKING_PLACE]: ['otherDataSectionParkingPlaceAtNight'],
  [VehicleInsuranceFormStepE.ESTIMATED_USED_ABROAD]: ['otherDataSectionVehicleEstimatedUsedAbroad'],
  [VehicleInsuranceFormStepE.WAS_VEHICLE_IMPORTED]: ['otherDataSectionVehicleWasImported'],
  [VehicleInsuranceFormStepE.IS_STEERING_WHEEL_ON_RIGHT]: ['otherDataSectionVehicleSteeringWheelOnRightSide'],
  [VehicleInsuranceFormStepE.IS_REGISTERED_AS_TRUCK]: ['otherDataSectionVehicleRegisteredAsTruck'],
  [VehicleInsuranceFormStepE.IS_VEHICLE_LEASED]: ['otherDataSectionVehicleIsLeased'],

  // OC history information
  [VehicleInsuranceFormStepE.LAST_INSURED_BY_CURRENT_OWNER]: ['ocSectionLastInsuredByCurrentOwner'],
  [VehicleInsuranceFormStepE.PREV_INSURED_COMPANY]: ['ocSectionPrevInsuredCompany'],
  [VehicleInsuranceFormStepE.PREV_OWNER_HAD_OC]: ['ocSectionPrevOwnerHadOc'],
  [VehicleInsuranceFormStepE.PREV_OWNER_OC_INSURED_COMPANY]: ['ocSectionPrevOwnerOcInsuredCompany'],

  // AC history information
  [VehicleInsuranceFormStepE.AC_LAST_INSURED_BY_CURRENT_OWNER]: ['acSectionLastInsuredByCurrentOwner'],
  [VehicleInsuranceFormStepE.AC_PREV_INSURED_COMPANY]: ['acSectionPrevInsuredCompany'],
  [VehicleInsuranceFormStepE.AC_PREV_OWNER_HAD_AC]: ['acSectionPrevOwnerHadAc'],
  [VehicleInsuranceFormStepE.AC_PREV_OWNER_AC_INSURED_COMPANY]: ['acSectionPrevOwnerAcInsuredCompany'],

  // Owner details
  [VehicleInsuranceFormStepE.OWNER_PESEL]: ['pesel'],
  [VehicleInsuranceFormStepE.OWNER_FIRST_NAME]: ['firstName'],
  [VehicleInsuranceFormStepE.OWNER_LAST_NAME]: ['lastName'],
  [VehicleInsuranceFormStepE.OWNER_EMAIL]: ['email'],
  [VehicleInsuranceFormStepE.OWNER_PHONE_NUMBER]: ['phone'],
  [VehicleInsuranceFormStepE.OWNER_POST_CODE]: [
    'ownerSectionPostCode',
    'ownerSectionCity',
    'ownerSectionStreet',
    'ownerSectionHouseNumber',
    'ownerSectionFlatNumber',
  ],

  // Summary & Offer
  [VehicleInsuranceFormStepE.SUMMARY]: [],
  [VehicleInsuranceFormStepE.OFFER_DOWNLOAD]: [],
};
