export enum UserJobPosition {
  Director = 1,
  ViceDirector = 2,
  Teacher = 3,
  AdministrationEmployee = 4,
  Other = 5,
}

export enum UserInstitutionJobVerificationStatus {
  NotVerified = 0,
  VerifiedPositive = 1,
  VerifiedNegative = 2,
}
