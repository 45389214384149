import React from 'react';
import * as styles from './styles';
import { useSwrAgent } from '../../../api/useSwrAgent';
import SkeletonTextLoader from '../../../components/Loadings/SkeletonTextLoader/SkeletonTextLoader';
import { Accordion, Card, Image } from 'react-bootstrap';
import { PathRoute } from '../../../constants/pathRoute/Route';
import LinkIcon from '../../../components/Icons/LinkIcon';
import { openLink } from '../../../utils/linkUtils';
import Cashback2MoneyTable from '../Cashback2InflowsTable/Cashback2MoneyTable';
import MyButton from '../../../components/Buttons/MyButton/MyButton';
import { useStore } from '../../../store/store';
import { history } from '../../../index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faCaretRight,
  faChevronCircleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

const Cashback2Main = (props: { goToPreviousStep: () => void }) => {
  const { modalStore } = useStore();

  const { data: cashback2BenefitInfo, isLoading } = useSwrAgent().Cashback2.GetCashback2BenefitInfo();
  //const { data: cashback2BenefitInfo, isLoading } = {
  //  data: {
  //    isCashback2BenefitActivated: true,
  //    hasOtherPolicy: false,
  //    cashback2BalanceDto: {
  //      customerInflows: [
  //        {
  //          policyNumber: '',
  //          policyCustomerName: '',
  //          pesel: '',
  //          cashback2StartDate: '',
  //          cashback2EndDate: '',
  //          cashback2InflowSourceName: 'BR',
  //          policyId: 30000,
  //          cashback2Amount: 400,
  //          productName: 'BR',
  //        },
  //      ],
  //      customerWithdrawals: [],
  //      customerInflowsPending: [],
  //      remainingCashback2: 400,
  //    },
  //    hasLifeCashback2Policy: true,
  //    hasNnwCashback2LinkPromotion: true,
  //  },
  //  isLoading: false,
  //};
  return (
    <>
      <div className={'cashback2 tw-py-2'}>
        <div className={'tw-flex tw-justify-center'}>
          <p style={styles.headingTextStyle}>Wykorzystaj Skarbonkę NAU</p>
        </div>
        <div className={'tw-mt-4 tw-flex tw-justify-center'}>
          <p style={styles.headingTextStyle}>Twoje saldo to</p>
        </div>{' '}
        <div className={'tw-flex tw-justify-center'}>
          <p style={styles.bigHeadingTextStyle}>
            {' '}
            {isLoading ? (
              <SkeletonTextLoader className={'tw-w-24'} />
            ) : (
              cashback2BenefitInfo?.cashback2BalanceDto?.remainingAmount.toFixed(2).replaceAll('.', ',') ?? '-'
            )}{' '}
            zł
          </p>
        </div>
        <p className={'tw-mb-8 tw-mt-4'} style={styles.textStyle}>
          <div className={'cashback2-instructions tw-mb-0'}>
            <Accordion>
              <Accordion.Item eventKey='0'>
                <Accordion.Header
                  style={
                    {
                      background: 'white',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      borderBottom: '1px solid #ECECEC',
                    } as React.CSSProperties
                  }>
                  Zobacz więcej szczegółów
                  <FontAwesomeIcon color='#00635c' icon={faChevronDown} size='2x' />
                </Accordion.Header>
                <Accordion.Body>
                  <div className={'tw-flex tw-items-center tw-gap-2'}>
                    {isLoading || !cashback2BenefitInfo ? (
                      <SkeletonTextLoader className={'tw-w-28'} />
                    ) : (
                      <Cashback2MoneyTable cashback2BenefitInfo={cashback2BenefitInfo} />
                    )}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          {(!cashback2BenefitInfo?.hasLifePolicyAllocation ||
            !cashback2BenefitInfo?.hasBrRrPolicyAllocation ||
            !cashback2BenefitInfo?.hasZnpAllocation) &&
            (cashback2BenefitInfo?.cashback2BalanceDto?.allocationsGrouped == undefined ||
              cashback2BenefitInfo?.cashback2BalanceDto?.allocationsGrouped.length < 4) && (
              <div className={'cashback2-instructions tw-mb-0'}>
                <Accordion>
                  <Accordion.Item eventKey='0'>
                    <Accordion.Header
                      style={
                        {
                          background: 'white',
                          paddingLeft: '10px',
                          paddingRight: '10px',
                          borderBottom: '1px solid #ECECEC',
                        } as React.CSSProperties
                      }>
                      Zyskaj więcej
                      <FontAwesomeIcon color='#00635c' icon={faChevronDown} size='2x' />
                    </Accordion.Header>
                    <Accordion.Body>
                      {!cashback2BenefitInfo?.hasLifePolicyAllocation && (
                        <div className={'tw-flex tw-items-center tw-justify-center tw-gap-2'}>
                          {cashback2BenefitInfo?.hasDirectorAllocation ? (
                            <Image src={'/assets/cashback2/cashback_main_life_200_tile.svg'} />
                          ) : (
                            <Image src={'/assets/cashback2/cashback_main_life_400_tile.svg'} />
                          )}
                        </div>
                      )}
                      {!cashback2BenefitInfo?.hasBrRrPolicyAllocation && (
                        <div className={'tw-flex tw-items-center tw-justify-center tw-gap-2'}>
                          <Image src={'/assets/cashback2/cashback_main_second_tile.svg'} />
                        </div>
                      )}
                      {!cashback2BenefitInfo?.hasZnpAllocation && (
                        <div className={'tw-flex tw-items-center tw-justify-center tw-gap-2'}>
                          <Image src={'/assets/cashback2/cashback_main_znp_tile.svg'} />
                        </div>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            )}
          <div
            style={{ boxShadow: 'inset 0px 3px 6px rgba(0, 0, 0, 0.08)', height: '10px' } as React.CSSProperties}></div>
          <div className='tw-mb-4 tw-flex tw-flex-col tw-gap-2'>
            <div className='tw-my-2 tw-text-center tw-font-bold tw-text-nau-green-dark'>
              Wybierz, na który z poniższych produktów, chcesz przeznaczyć środki
              <br /> ze swojej Skarbonki NAU
            </div>
            <div className={'tw-my-2 tw-flex tw-justify-center tw-px-2'}>
              <div className={'tw-grid tw-max-w-[400px] tw-grid-cols-2 tw-gap-4'}>
                <div
                  className={'tw-group tw-relative tw-flex tw-cursor-pointer tw-flex-col tw-items-center tw-gap-2'}
                  onClick={() => history.push(PathRoute.CAR_INSURANCE)}>
                  <div
                    style={
                      {
                        boxShadow: '0px 3px 6px #00000029',
                        border: '1px solid #A6B6D666',
                        borderRadius: '10px',
                        padding: '25px',
                      } as React.CSSProperties
                    }>
                    <Image src={'/assets/cashback/car.svg'} style={{ height: '50px' }} />
                  </div>
                  <div className={'tw-text-center tw-transition group-hover:tw-scale-105'} style={styles.textStyle}>
                    Ubezpieczenie komunikacyjne
                  </div>
                </div>
                <div
                  className={'tw-group tw-relative tw-flex tw-cursor-pointer tw-flex-col tw-items-center tw-gap-2'}
                  onClick={() => history.push(PathRoute.PROPERTY_INSURANCE)}>
                  <div
                    style={
                      {
                        boxShadow: '0px 3px 6px #00000029',
                        border: '1px solid #A6B6D666',
                        borderRadius: '10px',
                        padding: '25px',
                      } as React.CSSProperties
                    }>
                    <Image src={'/assets/cashback/shield.svg'} style={{ height: '50px' }} />
                  </div>
                  <div className={'tw-text-center'} style={styles.textStyle}>
                    Ubezpieczenie majątkowe
                  </div>
                </div>
                <div
                  className={'tw-group tw-relative tw-flex tw-cursor-pointer tw-flex-col tw-items-center tw-gap-2'}
                  onClick={() => history.push(PathRoute.TRIP_INSURANCE)}>
                  <div
                    style={
                      {
                        boxShadow: '0px 3px 6px #00000029',
                        border: '1px solid #A6B6D666',
                        borderRadius: '10px',
                        padding: '25px',
                      } as React.CSSProperties
                    }>
                    <Image src={'/assets/cashback/plane.svg'} style={{ height: '50px' }} />
                  </div>
                  <div className={'tw-text-center'} style={styles.textStyle}>
                    Ubezpieczenie turystyczne
                  </div>
                </div>
                <div
                  className={'tw-group tw-relative tw-flex tw-cursor-pointer tw-flex-col tw-items-center tw-gap-2'}
                  onClick={() => history.push(PathRoute.WALLET_NAU)}>
                  <div
                    style={
                      {
                        boxShadow: '0px 3px 6px #00000029',
                        border: '1px solid #A6B6D666',
                        borderRadius: '10px',
                        padding: '25px',
                      } as React.CSSProperties
                    }>
                    <Image src={'/assets/walletGreen.svg'} style={{ height: '50px' }} />
                  </div>
                  <div className={'tw-text-center'} style={styles.textStyle}>
                    Portfel
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p>
      </div>
    </>
  );
};

export default Cashback2Main;
