import React from 'react';
import { ICashback2ClientAllocationSummaryDTO } from '../../../types/cashback2';

interface ICashback2InflowsTableProps {
  inflows: ICashback2ClientAllocationSummaryDTO[];
  header?: string;
}

export const Cashback2InflowsTable = (props: ICashback2InflowsTableProps) => {
  return (
    <>
      {props.header && (
        <p className='tw-mb-2 tw-text-center tw-text-lg tw-font-semibold tw-text-[#00635c]'>{props.header}</p>
      )}
      <div className={'tw-overflow-x-auto tw-p-2'}>
        <div
          style={
            {
              borderRadius: '10px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            } as React.CSSProperties
          }>
          <div
            className='tw-grid tw-grid-cols-3 tw-text-white'
            style={
              {
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
                color: 'white',
                background: 'linear-gradient(to right,#00635c, #0bc268)',
              } as React.CSSProperties
            }>
            <div
              className='tw-flex tw-items-center tw-justify-center tw-border tw-border-gray-300 tw-px-2 tw-text-center tw-font-normal'
              style={
                {
                  borderLeft: 'none',
                  borderTop: 'none',
                  fontWeight: 700,
                  fontSize: '0.8rem',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                } as React.CSSProperties
              }>
              Saldo
            </div>
            <div
              className='tw-flex tw-items-center tw-justify-center tw-border tw-border-gray-300 tw-px-2 tw-text-center tw-font-normal'
              style={
                {
                  borderTop: 'none',
                  fontWeight: 700,
                  fontSize: '0.8rem',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                } as React.CSSProperties
              }>
              Źródło naliczenia
            </div>
            {/*<div
              className='tw-flex tw-items-center tw-justify-center tw-border tw-border-gray-300 tw-px-2 tw-text-center tw-font-normal'
              style={
                {
                  borderTop: 'none',
                  fontWeight: 700,
                  fontSize: '0.8rem',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                } as React.CSSProperties
              }>
              Produkt
            </div>*/}
            <div
              className='tw-flex tw-items-center tw-justify-center tw-border tw-border-gray-300 tw-px-2 tw-text-center tw-font-normal'
              style={
                {
                  borderRight: 'none',
                  borderTop: 'none',
                  fontWeight: 700,
                  fontSize: '0.8rem',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                } as React.CSSProperties
              }>
              Okres ważności
            </div>
          </div>
          <div>
            {props.inflows.map((inflow, i) => (
              <div className='tw-text-md tw-grid tw-grid-cols-3 tw-font-normal' key={i}>
                <div
                  className='tw-flex tw-items-center tw-justify-center tw-whitespace-nowrap tw-border tw-border-gray-300 tw-px-2 tw-py-2 tw-font-bold tw-text-nau-green-dark'
                  style={
                    i == props.inflows.length - 1
                      ? { borderBottomLeftRadius: '10px', borderBottom: 'none', borderLeft: 'none' }
                      : { borderLeft: 'none' }
                  }>
                  {inflow.amount?.toFixed(2) ?? '-'}
                </div>
                <div
                  className='tw-flex tw-items-center tw-justify-center tw-border tw-border-gray-300 tw-px-2 tw-py-2 tw-text-gray-700'
                  style={i == props.inflows.length - 1 ? { borderBottom: 'none' } : {}}>
                  {inflow.sourceTypeName}
                </div>
                {/*<div
                  className='tw-flex tw-items-center tw-justify-center tw-border tw-border-gray-300 tw-px-2 tw-py-2 tw-text-gray-700'
                  style={i == props.inflows.length - 1 ? { borderBottom: 'none' } : {}}>
                  {' '}
                </div>*/}
                <div
                  className='tw-flex tw-items-center tw-justify-center tw-border tw-border-gray-300 tw-px-2 tw-py-2 tw-text-sm tw-text-gray-600'
                  style={
                    i == props.inflows.length - 1
                      ? { borderBottomRightRadius: '10px', borderBottom: 'none', borderRight: 'none' }
                      : { borderRight: 'none' }
                  }>
                  <div>
                    <div className={'tw-whitespace-nowrap'}>
                      <span className='tw-text-xs'>od</span> {inflow.dateFromFormatted}
                    </div>
                    <div className={'tw-whitespace-nowrap'}>
                      <span className='tw-text-xs'>do</span> {inflow.dateToFormatted}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
