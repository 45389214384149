import { Spinner } from 'react-bootstrap';
import React, { useRef } from 'react';
import { twMerge } from '../../../index';
import { Tooltip } from 'react-tooltip';

const variantStyles = {
  primary:
    'tw-bg-nau-green-light after:tw-bg-nau-green-light tw-relative tw-z-10 tw-overflow-hidden tw-rounded-full tw-px-4 tw-py-2 tw-text-base tw-font-normal tw-text-white tw-shadow-md tw-transition-all tw-duration-500 after:tw-absolute after:tw-left-0 after:tw-top-0 after:tw-z-[-1] after:tw-h-full after:tw-w-full after:tw-transition-all after:tw-duration-500 after:tw-content-[""] hover:tw-bg-nau-green-dark hover:after:tw-left-[-101%]  after:disabled:tw-bg-gray-400 disabled:tw-bg-gray-400',
  greenDark:
    'tw-bg-nau-sea-green after:tw-bg-nau-sea-green tw-relative tw-z-10 tw-overflow-hidden tw-rounded-full tw-px-4 tw-py-2 tw-text-base tw-font-normal tw-text-white tw-shadow-md tw-transition-all tw-duration-500 after:tw-absolute after:tw-left-0 after:tw-top-0 after:tw-z-[-1] after:tw-h-full after:tw-w-full after:tw-transition-all after:tw-duration-500 after:tw-content-[""] hover:tw-bg-nau-green-dark hover:after:tw-left-[-101%] after:disabled:tw-bg-gray-400 disabled:tw-bg-gray-400',
  gray: 'tw-bg-nau-gray after:tw-bg-nau-gray tw-relative tw-z-10 tw-overflow-hidden tw-rounded-full tw-px-4 tw-py-2 tw-text-base tw-font-normal tw-text-white tw-shadow-md tw-transition-all tw-duration-500 after:tw-absolute after:tw-left-0 after:tw-top-0 after:tw-z-[-1] after:tw-h-full after:tw-w-full after:tw-transition-all after:tw-duration-500 after:tw-content-[""] hover:tw-bg-gray-600 hover:after:tw-left-[-101%]',
  whiteGreenBorder:
    'tw-bg-white tw-ring-2 tw-ring-inset tw-ring-nau-green-light tw-relative tw-z-10 tw-overflow-hidden tw-rounded-full tw-px-4 tw-py-2 tw-text-base tw-font-normal tw-text-black tw-shadow-md tw-transition-all tw-duration-500 hover:tw-bg-gray-100 disabled:tw-bg-gray-400',
  white:
    'tw-bg-white tw-relative tw-z-10 tw-overflow-hidden tw-rounded-full tw-px-4 tw-py-2 tw-text-base tw-font-normal tw-text-black tw-shadow-md tw-transition-all tw-duration-500 hover:tw-bg-gray-100 disabled:tw-bg-gray-400',
  blue: 'tw-flex  tw-items-center tw-justify-center tw-rounded-md tw-bg-blue-50 tw-py-2 tw-text-center tw-text-sm tw-font-medium tw-text-blue-700 tw-transition-colors hover:tw-bg-blue-100',
};

interface IButtonProps {
  variant?: keyof typeof variantStyles;
  className?: string;
  children?: React.ReactNode;
  href?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  isLoading?: boolean;
  loadingText?: string;
  disabledText?: string;
  onClick?: () => void;
}

export default function MyButton({ variant, className, children, ...props }: IButtonProps) {
  const tooltipIdRef = useRef<string>(Math.random().toString());
  className = twMerge(
    'tw-inline-flex tw-gap-0.5 tw-justify-center tw-overflow-hidden tw-text-sm tw-font-medium tw-transition tw-items-center',
    variant && variantStyles[variant],
    className,
  );

  return (
    <>
      <Tooltip id={tooltipIdRef.current} />
      <button
        className={className}
        {...props}
        data-tooltip-content={props.isLoading ? 'Ładowanie' : props.disabled ? props.disabledText : ''}
        data-tooltip-delay-show={200}
        data-tooltip-id={tooltipIdRef.current}
        disabled={props.isLoading ? props.isLoading : props.disabled}
        onClick={props.onClick}>
        {props.isLoading ? (
          <div className={'tw-flex tw-items-center'}>
            <span className='tw-mr-4'>
              <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
            </span>
            <div>{props.loadingText ?? 'Ładowanie'}</div>
          </div>
        ) : (
          <>{children}</>
        )}
      </button>
    </>
  );
}
