import React, { useEffect, useState } from 'react';
import { motion, useReducedMotion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

// Interface for GradientOrb props
interface GradientOrbProps {
  color: string;
  size?: number;
  opacity?: number;
  blur?: number;
  duration?: number;
}

// Interface for MeshGradient props
interface MeshGradientProps {
  wrapperClassName?: string;
  intensity?: 'subtle' | 'medium' | 'strong';
  speed?: 'slow' | 'medium' | 'fast';
  itemClassName?: string;
  size?: number;
}

// Function to generate random movement within a range
const getRandomTransform = (): { x: number; y: number } => {
  const x = Math.random() * 400 - 200; // -200 to 200
  const y = Math.random() * 400 - 200; // -200 to 200
  return { x, y };
};

// GradientOrb component
const GradientOrb: React.FC<GradientOrbProps> = ({ color, size = 300, opacity = 0.15, blur = 80, duration = 15 }) => {
  const shouldReduceMotion = useReducedMotion();
  const [position, setPosition] = useState<{ x: number; y: number }>(getRandomTransform());

  useEffect(() => {
    if (shouldReduceMotion) return; // Skip animation updates on reduced motion

    const interval = setInterval(() => {
      setPosition(getRandomTransform());
    }, duration * 1000);

    return () => clearInterval(interval);
  }, [duration, shouldReduceMotion]);

  return (
    <motion.div
      animate={shouldReduceMotion ? {} : { x: position.x + '%', y: position.y + '%' }}
      className={twMerge(
        'tw-absolute tw-rounded-full',
        'tw-pointer-events-none tw-mix-blend-screen',
        'tw-left-1/2 tw-top-1/2',
        'tw--translate-x-1/2 tw--translate-y-1/2',
        color,
      )}
      style={{
        width: size,
        height: size,
        opacity,
        filter: `blur(${blur}px)`,
      }}
      transition={{
        duration: duration,
        ease: [0.45, 0, 0.55, 1],
        type: 'tween',
      }}
    />
  );
};

// MeshGradient component
export const MeshGradient: React.FC<MeshGradientProps> = ({
  wrapperClassName,
  intensity = 'subtle',
  speed = 'slow',
  itemClassName,
  size = 300,
}) => {
  const opacityMap: Record<'subtle' | 'medium' | 'strong', number> = {
    subtle: 0.15,
    medium: 0.2,
    strong: 0.25,
  };

  const speedMap: Record<'slow' | 'medium' | 'fast', number> = {
    slow: 4,
    medium: 2.7,
    fast: 1.7,
  };

  const opacity = opacityMap[intensity] || opacityMap.subtle;
  const duration = speedMap[speed] || speedMap.slow;

  // Create more orbs with varied colors and larger sizes
  const orbConfigs: { color: string; size: number }[] = [
    { color: 'tw-bg-green-500', size: size * 1.2 },
    { color: 'tw-bg-green-400', size: size * 1.4 },
    { color: 'tw-bg-green-300', size: size * 1.3 },
    { color: 'tw-bg-emerald-500', size: size * 1.1 },
    { color: 'tw-bg-emerald-400', size: size * 1.0 },
    { color: 'tw-bg-lime-500', size: size * 1.2 },
    { color: 'tw-bg-lime-400', size: size * 1.1 },
    { color: 'tw-bg-teal-500', size: size * 1.3 },
    { color: 'tw-bg-teal-400', size: size * 1.0 },
    { color: 'tw-bg-green-200', size: size * 1.2 },
    { color: 'tw-bg-emerald-300', size: size * 0.9 },
    { color: 'tw-bg-lime-300', size: size * 1.1 },
  ];

  return (
    <div className={twMerge('tw-absolute tw-inset-0 tw-overflow-hidden ', wrapperClassName)}>
      <div className={twMerge('tw-absolute tw-inset-0', itemClassName)}>
        {orbConfigs.map((config, index) => (
          <GradientOrb
            blur={60 + Math.random() * 40} // Random blur between 60 and 100
            color={config.color}
            duration={duration * (0.9 + Math.random() * 0.2)} // Smaller random duration variation
            key={index}
            opacity={opacity}
            size={config.size}
          />
        ))}
        <div className='tw-bg-background/50 tw-absolute tw-inset-0' />
      </div>
    </div>
  );
};
