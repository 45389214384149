import React, { useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { AdditionalField } from '../../../models/AdditionalField';

interface DynamicFieldsFormProps {
  fields: AdditionalField[];
  onSubmit: (values: Record<string, string>) => void;
  onCancel: () => void;
  isLoading: boolean;
}

const DynamicFieldsForm: React.FC<DynamicFieldsFormProps> = ({ fields, onSubmit, onCancel, isLoading }) => {
  const [formValues, setFormValues] = useState<Record<string, string>>({});
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});

  // Initialize form values with default values from fields
  useEffect(() => {
    const initialValues: Record<string, string> = {};
    fields.forEach((field) => {
      // For checkboxes, ensure the value is a string 'true' or 'false'
      if (field.fieldType?.toLowerCase() === 'checkbox') {
        initialValues[field.name] = field.value === 'true' ? 'true' : 'false';
      } else {
        initialValues[field.name] = field.value || '';
      }
    });
    setFormValues(initialValues);
  }, [fields]);

  const handleChange = (fieldName: string, value: string) => {
    setFormValues((prev) => ({
      ...prev,
      [fieldName]: value,
    }));

    // Clear validation error when user types
    if (validationErrors[fieldName]) {
      setValidationErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[fieldName];
        return newErrors;
      });
    }
  };

  const validateForm = (): boolean => {
    const errors: Record<string, string> = {};

    fields.forEach((field) => {
      if (field.fieldType?.toLowerCase() === 'checkbox') {
        // For checkboxes, consider 'false' as filled (even if not checked)
        // Only validate required checkboxes that must be checked (true)
        if (field.required && formValues[field.name] !== 'true') {
          errors[field.name] = 'To pole musi być zaznaczone';
        }
      } else if (field.required && !formValues[field.name]) {
        errors[field.name] = 'Pole wymagane';
      }

      // Add date validation
      if (field.fieldType?.toLowerCase() === 'date' && formValues[field.name]) {
        const dateValue = new Date(formValues[field.name]);

        if (field.minDate && new Date(field.minDate) > dateValue) {
          errors[field.name] = `Data nie może być wcześniejsza niż ${field.minDate}`;
        }

        if (field.maxDate && new Date(field.maxDate) < dateValue) {
          errors[field.name] = `Data nie może być późniejsza niż ${field.maxDate}`;
        }
      }
    });

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (validateForm()) {
      onSubmit(formValues);
    }
  };

  // Helper function to determine if a checkbox is checked based on string value
  const isChecked = (value: string | undefined): boolean => {
    return value === 'true';
  };

  const renderField = (field: AdditionalField) => {
    // Default to 'checkbox' if fieldType is null or undefined
    const fieldType = field.fieldType?.toLowerCase() || 'checkbox';

    switch (fieldType) {
      case 'text':
      case 'email':
      case 'number':
      case 'tel':
        return (
          <Form.Control
            isInvalid={!!validationErrors[field.name]}
            name={field.name}
            onChange={(e) => handleChange(field.name, e.target.value)}
            placeholder={field.placeholder}
            type={fieldType}
            value={formValues[field.name] || ''}
          />
        );

      case 'date':
        return (
          <Form.Control
            isInvalid={!!validationErrors[field.name]}
            name={field.name}
            onChange={(e) => handleChange(field.name, e.target.value)}
            type='date'
            min={field.minDate}
            max={field.maxDate}
            value={formValues[field.name] || ''}
          />
        );

      case 'select': {
        // Special handling for 'Typ ulicy' - ensure it includes 'Ulica' option
        let options = field.options || [];
        if (field.label === 'Typ ulicy' && !options.includes('Ulica')) {
          options = ['Ulica', ...options];
        }

        return (
          <Form.Select
            isInvalid={!!validationErrors[field.name]}
            name={field.name}
            onChange={(e) => handleChange(field.name, e.target.value)}
            value={formValues[field.name] || ''}>
            <option value=''>{'wybierz...'}</option>
            {options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </Form.Select>
        );
      }

      case 'radio':
        return (
          <div>
            {field.options?.map((option, index) => (
              <Form.Check
                checked={formValues[field.name] === option}
                id={`${field.name}-${index}`}
                isInvalid={!!validationErrors[field.name]}
                key={index}
                label={option}
                name={field.name}
                onChange={() => handleChange(field.name, option)}
                type='radio'
                value={option}
              />
            ))}
          </div>
        );

      case 'checkbox':
        return (
          <Form.Check
            checked={isChecked(formValues[field.name])}
            feedback={validationErrors[field.name]}
            feedbackType='invalid'
            id={field.name}
            isInvalid={!!validationErrors[field.name]}
            label={field.label}
            name={field.name}
            onChange={(e) => handleChange(field.name, e.target.checked ? 'true' : 'false')}
            type='checkbox'
          />
        );

      case 'textarea':
        return (
          <Form.Control
            as='textarea'
            isInvalid={!!validationErrors[field.name]}
            name={field.name}
            onChange={(e) => handleChange(field.name, e.target.value)}
            placeholder={field.placeholder}
            rows={3}
            value={formValues[field.name] || ''}
          />
        );

      default:
        return (
          <Form.Control
            isInvalid={!!validationErrors[field.name]}
            name={field.name}
            onChange={(e) => handleChange(field.name, e.target.value)}
            type='text'
            value={formValues[field.name] || ''}
          />
        );
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {fields.map((field, index) => (
        <Form.Group className='mb-3' key={index}>
          {field.fieldType?.toLowerCase() !== 'checkbox' && (
            <Form.Label>
              {field.label}
              {field.required && <span className='text-danger'>*</span>}
            </Form.Label>
          )}

          {renderField(field)}

          {validationErrors[field.name] && field.fieldType?.toLowerCase() !== 'checkbox' && (
            <Form.Control.Feedback type='invalid'>{validationErrors[field.name]}</Form.Control.Feedback>
          )}
        </Form.Group>
      ))}

      <div className='d-flex justify-content-end mt-4'>
        <Button className='me-2' disabled={isLoading} onClick={onCancel} variant='outline-secondary'>
          Anuluj
        </Button>

        <Button
          disabled={isLoading}
          onClick={() => {
            if (validateForm()) {
              onSubmit(formValues);
            }
          }}
          type='button'
          variant='primary'>
          {isLoading ? (
            <>
              <Spinner animation='border' className='me-2' size='sm' />
              Ładowanie
            </>
          ) : (
            'Kontynuuj'
          )}
        </Button>
      </div>
    </Form>
  );
};

export default DynamicFieldsForm;
