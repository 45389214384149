import useSWR from 'swr';
import { DiscountCodeContractStatus, DiscountCodeM } from '../types/discountCode';
import useSwrArticlesAxios from './useSwrArticlesAxios';
import getUrls from './getUrls';
import { UserPayment } from '../types/payments';
import { UserMarketingData, UserProfileNewModel } from '../types/user';
import { ICashbackBalanceInfo, ICashbackBenefitInfo } from '../types/cashback';
import { CinemaVoucherM } from '../types/cinemaVoucher';
import { CinemaVoucherOrderPromo } from '../enums/cinemaVoucherOrderPromo.enum';
import { ISportCardDto } from '../types/sportCard';
import { ContractModel, PhoneModel } from '../types/phoneSubscription';
import { InstitutionEmployeeDTO, InstitutionFundDTO } from '../types/institutionAdmin';
import { IFCustomerFundOrderDTO } from '../types/institutionFund';
import {
  EmployeeProductSettlementFromSopDto,
  EmployeeSettlementsFromSopDto,
  InstitutionSettlementFromSop,
  ProductSettlementsFromSopDto,
} from '../types/institutionSettlements';
import { KlProduct } from '../types/kl';
import { Nau24CalculationOfferModel } from '../containers/InsurancePurchaseForms/VehicleInsurance/types';
import { UserVehicleFromSopDto } from '../types/vehicle';
import { ICashback2BalanceInfo, ICashback2BenefitInfo } from '../types/cashback2';

export function useSwrAgent() {
  const Article = {
    getArticle: useSwrArticlesAxios(),
  };

  const BirthdayCodes = {
    GetUserBirthdayCodeCampaigns: () => useSWR<DiscountCodeM[]>(getUrls.BirthdayCodes.getUserBirthdayCodes),
  };

  const Cashback = {
    GetCashbackBenefitInfo: () => useSWR<ICashbackBenefitInfo>(getUrls.Cashback.getCashbackBenefitInfo),
    GetCashbackBalance: (shouldFetch = true) => {
      return useSWR<ICashbackBalanceInfo>(shouldFetch ? getUrls.Cashback.getCashbackBalance : null);
    },
  };
  const Cashback2 = {
    GetCashback2BenefitInfo: () => useSWR<ICashback2BenefitInfo>(getUrls.Cashback2.getCashback2BenefitInfo),
    GetCashback2Balance: (shouldFetch = true) => {
      return useSWR<ICashback2BalanceInfo>(shouldFetch ? getUrls.Cashback2.getCashback2Balance : null);
    },
  };

  const DiscountCodes = {
    GetUserDiscountCodes: () => useSWR<DiscountCodeM[]>(getUrls.DiscountCodes.getUserDiscountCodes),
    GetUserDiscountCodeContractStatus: () => useSWR<number>(getUrls.DiscountCodes.getUserDiscountCodeContractStatus),
    GetAllUserDiscountCodeContracts: () =>
      useSWR<DiscountCodeContractStatus[]>(getUrls.DiscountCodes.getAllUserDiscountCodeContracts),
    CheckHasNotSettledSettlements: () => useSWR<boolean>(getUrls.DiscountCodes.checkHasNotSettledSettlements),
  };

  const Payments = {
    GetUserPayments: () => useSWR<UserPayment[]>(getUrls.Payments.getUserPayments),
  };

  const Users = {
    GetUserMarketingData: () => useSWR<UserMarketingData>(getUrls.User.getUserMarketingData),
    HasUserPersonalData: () => useSWR<boolean>(getUrls.User.hasUserPersonalData),
    HasUserJobPositionData: () => useSWR<boolean>(getUrls.User.hasUserJobPositionData),
    IsUserAssignedToInstitutionAsDirector: () => useSWR<boolean>(getUrls.User.isUserAssignedToInstitutionAsDirector),
    GetUserProfile: () => useSWR<UserProfileNewModel>(getUrls.User.getUserProfileNew),
  };

  const Wallet = {
    GetWalletContractStatus: () => useSWR<number>(getUrls.Wallet.getWalletContractStatus),
  };
  const CinemaVouchers = {
    GetUserCinemaVouchers: () => useSWR<CinemaVoucherM[]>(getUrls.CinemaVouchers.getUserCinemaVouchers),
    CheckUserHasCinemaVoucherOrderWithPromo: (promoId: CinemaVoucherOrderPromo) =>
      useSWR<boolean>(`/CinemaVoucher/CheckUserHasCinemaVoucherOrderWithPromo?promoId=${promoId}`),
    CheckUserHasAvailableNauWorkerPromo: () => useSWR<boolean>(`/CinemaVoucher/CheckUserHasAvailableNauWorkerPromo`),
    CheckUserHasAvailableWomensDayPromo: () => useSWR<boolean>(`/CinemaVoucher/CheckUserHasAvailableWomensDayPromo`),
    CheckIfUserHasCinemaVoucherPersonalPromotions: () =>
      useSWR<boolean>(`/CinemaVoucher/CheckIfUserHasCinemaVoucherPersonalPromotions`),
  };

  const SportCards = {
    GetSportCardsForUser: (sportCardCompany?: number) =>
      useSWR<ISportCardDto[]>(
        sportCardCompany != null
          ? `${getUrls.SportCards.getSportCardsForUser}?sportCardCompany=${sportCardCompany}`
          : getUrls.SportCards.getSportCardsForUser,
      ),
  };

  const NauMobile = {
    GetAvailablePhonesModel: () => useSWR<PhoneModel[]>(getUrls.NauMobile.getAvailablePhonesModel),
    GetAvailableProviderContracts: (providerTypeId: number) =>
      useSWR<ContractModel[]>(getUrls.NauMobile.getAvailableProviderContracts + providerTypeId),
  };

  const InstitutionAdmin = {
    GetFundDetails: (institutionId: number) =>
      useSWR<InstitutionFundDTO>(`${getUrls.InstitutionAdmin.getFundDetails}?institutionId=${institutionId}`),
    GetEmployees: (institutionId: number) =>
      useSWR<InstitutionEmployeeDTO[]>(`${getUrls.InstitutionAdmin.getEmployees}?institutionId=${institutionId}`),
    GetFundOrders: (institutionId: number) =>
      useSWR<IFCustomerFundOrderDTO[]>(`${getUrls.InstitutionAdmin.getFundOrders}?institutionId=${institutionId}`),
    HasAcceptedFundRegulationsAuthorization: (userInstitutionId: number) =>
      useSWR<boolean>(
        `${getUrls.InstitutionAdmin.hasAcceptedFundRegulationsAuthorization}?userInstitutionId=${userInstitutionId}`,
      ),
    GetInstitutionSettlements: (institutionId: number) =>
      useSWR<InstitutionSettlementFromSop[]>(
        `${getUrls.InstitutionAdmin.getInstitutionSettlements}?institutionId=${institutionId}`,
      ),
    GetEmployeeSettlementsForSettlement: (settlementId: number, institutionId: number) =>
      useSWR<EmployeeSettlementsFromSopDto[]>(
        `${getUrls.InstitutionAdmin.getEmployeeSettlementsForSettlement}?settlementId=${settlementId}&institutionId=${institutionId}`,
      ),
    GetProductSettlementsForSettlement: (settlementId: number, institutionId: number) =>
      useSWR<ProductSettlementsFromSopDto[]>(
        `${getUrls.InstitutionAdmin.getProductSettlementsForSettlement}?settlementId=${settlementId}&institutionId=${institutionId}`,
      ),
    GetEmployeeProductSettlements: (settlementId: number, institutionId: number, employeeId: number) =>
      useSWR<EmployeeProductSettlementFromSopDto[]>(
        `${getUrls.InstitutionAdmin.getEmployeeProductSettlements}?settlementId=${settlementId}&institutionId=${institutionId}&employeeId=${employeeId}`,
      ),
  };
  const Kl = {
    GetAllVariants: () => useSWR<KlProduct[]>(getUrls.Kl.getAllVariant),
  };

  const AiOffice = {
    GetNau24Offer: (calculationId: number) =>
      useSWR<Nau24CalculationOfferModel>(getUrls.AiOffice.getNau24Offer + calculationId, {
        refreshInterval: 5000,
      }),
    GetUserVehicles: () => useSWR<UserVehicleFromSopDto[]>(getUrls.AiOffice.getUserVehicles),
  };

  return {
    AiOffice,
    Article,
    BirthdayCodes,
    Cashback,
    Cashback2,
    CinemaVouchers,
    DiscountCodes,
    NauMobile,
    Payments,
    SportCards,
    Users,
    Wallet,
    InstitutionAdmin,
    Kl,
  };
}
