import React, { useState } from 'react';
import MyButton from '../../../components/Buttons/MyButton/MyButton';
import { useStore } from '../../../store/store';
import { history } from '../../../index';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { FileDown, LoaderCircle } from 'lucide-react';
import agent from '../../../api/agent';
import { FileInterface, SelectOfferRequest } from './types';
import { AdditionalField } from '../../../models/AdditionalField';
import DynamicFieldsForm from './DynamicFieldsForm';
import { AnimatePresence, motion } from 'framer-motion';
import { VehicleInsuranceCompany, VehicleInsuranceFormStepE } from './vehicleInsuranceEnum';
import { useVehicleInsuranceStore } from '../../../store/VehicleInsurance/vehicleInsuranceStore';
import VehicleInsuranceModalContent from './VehicleInsuranceModalContent';
import { toast } from 'react-toastify';

interface IVehicleInsuranceOfferPaymentModalProps {
  basePrice: number;
  reducedPrice?: number;
  offerSopId: number;
  vehicleInsuranceCalculationId: number;
  onCancel?: () => void;
  showAsInline?: boolean;
  insuranceVehicleCompany: VehicleInsuranceCompany;
}

const downloadFile = (file: FileInterface) => {
  const link = document.createElement('a');
  link.href = `data:${file.contentType};base64,${file.fileBase64}`;
  link.download = file.fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function VehicleInsuranceOfferSelection(props: IVehicleInsuranceOfferPaymentModalProps) {
  const { modalStore } = useStore();
  const { setCurrentStep } = useVehicleInsuranceStore();

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [step, setStep] = useState<'initial' | 'processing' | 'complete' | 'additional-fields'>('initial');
  const [additionalFields, setAdditionalFields] = useState<AdditionalField[]>([]);
  const [additionalFieldValues, setAdditionalFieldValues] = useState<Record<string, string>>({});
  const [currentOperation, setCurrentOperation] = useState<'purchase' | 'offer' | 'owu' | null>(null);

  const handleMissingFieldsForSomeCompanies = () => {
    if (props.insuranceVehicleCompany === VehicleInsuranceCompany.TRASTI) {
      return [
        {
          name: 'trasti_vehicle_registration_date',
          fieldType: 'date',
          label: 'Data rejestracji pojazdu przez obecnego właściciela',
          maxDate: new Date().toISOString(),
          required: true,
        } as AdditionalField,
      ];
    }
    return undefined;
  };

  const handleOperation = async (
    operation: 'purchase' | 'offer' | 'owu',
    additionalFields?: Record<string, string>,
  ) => {
    try {
      if (step === 'initial') {
        setCurrentOperation(operation);
        const additionalFieldsForCompany = handleMissingFieldsForSomeCompanies();
        if (additionalFieldsForCompany) {
          setAdditionalFields(additionalFieldsForCompany);
          setStep('additional-fields');
          return;
        }
      }

      setIsProcessing(true);
      setStep('processing');

      const requestData: SelectOfferRequest = {
        offerSopId: props.offerSopId,
        vehicleInsuranceCalculationId: props.vehicleInsuranceCalculationId,
        additionalFields: additionalFields ?? additionalFieldValues,
        operation: operation,
      };

      const response = await agent.AiOffice.selectVehicleInsuranceOffer(requestData);

      // Handle file downloads
      if (operation === 'offer' && response.offerFile) {
        downloadFile(response.offerFile);
        setIsProcessing(false);
        setStep('initial');
        setCurrentOperation(null);
        toast.success('Oferta została pobrana.');
        return;
      }

      if (operation === 'owu' && response.owuFile) {
        downloadFile(response.owuFile);
        setIsProcessing(false);
        setStep('initial');
        setCurrentOperation(null);
        toast.success('OWU zostało pobrane.');
        return;
      }

      // Handle additional fields
      if (response.status === 'ADDITIONAL_FIELDS_REQUIRED') {
        setAdditionalFields(response.additionalFields || []);
        setStep('additional-fields');
        setIsProcessing(false);
        return;
      }

      // Handle price changes
      if (response.status === 'NEW_PRICE_AVAILABLE') {
        modalStore.openModal(
          <VehicleInsuranceModalContent
            additionalContent={
              <div className={'tw-flex tw-justify-end tw-gap-4'}>
                <MyButton
                  onClick={() => {
                    handleOperation('purchase');
                    modalStore.closeModal();
                  }}
                  type={'button'}
                  variant='primary'>
                  Potwierdzam
                </MyButton>
              </div>
            }
            message={`Cena polisy uległa zmianie z ${props.basePrice} zł na ${response.newPrice} zł. Prosimy ponownie potwierdzić zakup.`}
            title='Cena polisy uległa zmianie'
          />,
          'lg',
          () => <></>,
          undefined,
          'tw-p-0',
        );
        setIsProcessing(false);
        setStep('initial');
        return;
      }

      // Handle session expiry
      if (response.status === 'ERROR' && response.sessionExpired) {
        if (!props.showAsInline) {
          modalStore.closeModal();
        }
        setIsProcessing(false);
        setStep('initial');
        setCurrentStep(VehicleInsuranceFormStepE.SUMMARY);
        modalStore.openModal(
          <VehicleInsuranceModalContent
            additionalContent={
              <div className={'tw-flex tw-justify-end tw-gap-4'}>
                <MyButton
                  onClick={() => {
                    modalStore.closeModal();
                  }}
                  type={'button'}
                  variant='gray'>
                  Zamknij
                </MyButton>
              </div>
            }
            message='Niestety, Twoja sesja wygasła. Aby zapewnić Ci najwyższą jakość usług, prosimy o ponowne wykonanie kalkulacji.'
            title='Sesja wygasła'
          />,
          'lg',
          () => <></>,
          undefined,
          'tw-p-0',
        );
        return;
      }

      // Handle successful purchase
      if (operation === 'purchase') {
        setStep('complete');
        setTimeout(() => {
          history.push(PathRoute.USER_VEHICLES);
          if (!props.showAsInline) {
            modalStore.closeModal();
          }
        }, 1500);
      }
    } catch (error) {
      console.error('Error during operation:', error);
      setStep('initial');
    } finally {
      setIsProcessing(false);
    }
  };

  const submitAdditionalFields = async (fieldValues: Record<string, string>) => {
    setAdditionalFieldValues(fieldValues);
    console.log(currentOperation);
    if (currentOperation) {
      await handleOperation(currentOperation, fieldValues);
    }
  };

  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    } else if (!props.showAsInline) {
      modalStore.closeModal();
    }
  };

  // Animation variants
  const fadeVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
      },
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.3,
      },
    },
  };

  const renderContent = () => {
    return (
      <AnimatePresence mode='wait'>
        {step === 'processing' && (
          <motion.div
            animate='visible'
            className='tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-8'
            exit='exit'
            initial='hidden'
            key='processing'
            variants={fadeVariants}>
            <LoaderCircle className='tw-text-nau-primary tw-h-16 tw-w-16 tw-animate-spin' />
            <p className='tw-mt-4 tw-text-center tw-text-lg tw-font-medium tw-text-nau-kids-gray'>
              Przetwarzamy Twoje żądanie...
            </p>
            <p className='tw-mt-2 tw-text-center tw-text-sm tw-text-nau-kids-gray'>
              Może to potrwać kilka minut. Proszę nie zamykać tej strony.
            </p>
          </motion.div>
        )}

        {step === 'additional-fields' && (
          <motion.div
            animate='visible'
            className='tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-4'
            exit='exit'
            initial='hidden'
            key='additional-fields'
            variants={fadeVariants}>
            <h3 className='tw-mb-4 tw-text-center tw-text-lg tw-font-medium tw-text-nau-kids-gray'>
              Wymagane dodatkowe informacje
            </h3>
            <div className='tw-w-full'>
              <DynamicFieldsForm
                fields={additionalFields}
                isLoading={isProcessing}
                onCancel={() => handleCancel()}
                onSubmit={submitAdditionalFields}
              />
            </div>
          </motion.div>
        )}

        {step === 'complete' && (
          <motion.div
            animate='visible'
            className='tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-8'
            exit='exit'
            initial='hidden'
            key='complete'
            variants={fadeVariants}>
            <div className='tw-flex tw-h-16 tw-w-16 tw-items-center tw-justify-center tw-rounded-full tw-bg-green-100'>
              <svg className='tw-h-8 tw-w-8 tw-text-green-600' fill='none' stroke='currentColor' viewBox='0 0 24 24'>
                <path d='M5 13l4 4L19 7' strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} />
              </svg>
            </div>
            <p className='tw-mt-4 tw-text-center tw-text-lg tw-font-medium tw-text-nau-kids-gray'>
              Twoje zamówienie zostało przyjęte!
            </p>
            <p className='tw-mt-2 tw-text-center tw-text-sm tw-text-nau-kids-gray'>
              Za chwilę zostaniesz przekierowany.
            </p>
          </motion.div>
        )}

        {step === 'initial' && (
          <motion.div animate='visible' exit='exit' initial='hidden' key='initial' variants={fadeVariants}>
            <div className='tw-mb-4 tw-text-xl tw-font-bold tw-text-nau-kids-gray'>
              Potwierdzenie zakupu ubezpieczenia
            </div>

            <div className='tw-mb-6 tw-flex tw-flex-col tw-gap-4'>
              <div className='tw-flex tw-flex-col tw-gap-2'>
                <p className='tw-text-sm tw-text-nau-kids-gray'>Przed zakupem możesz pobrać:</p>
                <div className='tw-flex tw-flex-row tw-gap-2'>
                  <MyButton
                    disabled={isProcessing}
                    onClick={() => handleOperation('offer')}
                    type={'button'}
                    variant='gray'>
                    <div className='tw-flex tw-items-center tw-gap-2'>
                      <FileDown className='tw-h-4 tw-w-4' />
                      <span>Pobierz ofertę</span>
                    </div>
                  </MyButton>
                  <MyButton
                    disabled={isProcessing}
                    onClick={() => handleOperation('owu')}
                    type={'button'}
                    variant='gray'>
                    <div className='tw-flex tw-items-center tw-gap-2'>
                      <FileDown className='tw-h-4 tw-w-4' />
                      <span>Pobierz OWU</span>
                    </div>
                  </MyButton>
                </div>
              </div>

              <div className='tw-mt-4 tw-rounded-lg tw-bg-blue-50 tw-p-4'>
                <p className='tw-text-sm tw-text-blue-800'>
                  Ta oferta została starannie przygotowana przez naszych ekspertów, aby zapewnić Ci najlepszą ochronę w
                  najkorzystniejszej cenie.
                </p>
              </div>
            </div>

            <div className='tw-flex tw-flex-col tw-justify-end tw-gap-2 tw-border-t tw-border-gray-200 tw-pt-4 sm:tw-flex-row'>
              {props.showAsInline && (
                <MyButton disabled={isProcessing} onClick={handleCancel} type={'button'} variant='gray'>
                  Anuluj
                </MyButton>
              )}
              <MyButton
                disabled={isProcessing}
                isLoading={isProcessing}
                onClick={() => handleOperation('purchase')}
                type={'button'}
                variant='primary'>
                Zamów polisę
              </MyButton>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  };

  return <div className=''>{renderContent()}</div>;
}
