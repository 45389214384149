import { twMerge } from '../../index';
import { LoaderCircle } from 'lucide-react';

interface ISpinnerProps {
  className?: string;
}

export const MySpinner = (props: ISpinnerProps) => {
  return (
    <LoaderCircle className={twMerge('tw-inline-block tw-h-6 tw-w-6 tw-animate-spin', props.className)}></LoaderCircle>
  );
};
