import React, { useEffect, useState } from 'react';
import { useSwrAgent } from '../../../api/useSwrAgent';
import LoadingComponent from '../../../components/Loadings/LoadingComponent/LoadingComponent';
import Cashback2InitialPage from '../Cashback2InitialPage/Cashback2InitialPage';
import { toast } from 'react-toastify';
import { mutate } from 'swr';
import getUrls from '../../../api/getUrls';
import ApprovalsForm from '../../../components/ApprovalsForm/ApprovalsForm';
import { Benefit } from '../../../enums/benefits.enums';
import { useStore } from '../../../store/store';
import Cashback2Main from '../Cashback2Main/Cashback2Main';
import { UserInstitutionJobVerificationStatus } from '../../../enums/userJobPosition.enums';
import Cashback2VerificationInProgress from '../Cashback2VerificationInProgress/Cashback2VerificationInProgress';
import { UserJobPositionData } from '../../../types/user';

const Cashback2Page = () => {
  const { userStore } = useStore();
  //const { data: cashback2BenefitInfo, isLoading } = useSwrAgent().Cashback2.GetCashback2BenefitInfo();
  //const { data: cashback2BenefitInfo, isLoading } = {
  //  data: {
  //    isCashback2BenefitActivated: true,
  //    hasOtherPolicy: false,
  //    cashback2BalanceDto: {},
  //    hasLifeCashback2Policy: true,
  //    hasNnwCashback2LinkPromotion: true,
  //  },
  //  isLoading: false,
  //};
  const [hasApprovals, setApprovals] = useState<boolean>(false);
  const [jobPositionData, setJobPositionData] = useState<UserJobPositionData>();
  const [step, setStep] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const goToNextStep = () => {
    setStep(step + 1);
  };
  const goToPreviousStep = () => {
    setStep(step - 1);
  };
  const getCashback2View = () => {
    if (!hasApprovals) {
      return <ApprovalsForm benefitId={Benefit.Cashback} hideHeader={true} />;
    }
    if (jobPositionData?.verificationStatus == UserInstitutionJobVerificationStatus.NotVerified) {
      return <Cashback2VerificationInProgress />;
    }
    if (step === 1) {
      return <Cashback2InitialPage goToNextStep={() => goToNextStep()} goToPreviousStep={() => goToPreviousStep()} />;
    }
    if (step === 2) {
      return <Cashback2Main goToPreviousStep={() => goToPreviousStep()} />;
    }
    return <></>;
  };

  useEffect(() => {
    const hasApprovalsPromise = userStore.hasRequiredApprovalsForBenefit(Benefit.Cashback);
    const jobPositionDataPromise = userStore.setJobPositionData();

    Promise.all([hasApprovalsPromise, jobPositionDataPromise])
      .then(([approvalsResp, jobPositionDataResp]) => {
        setApprovals(approvalsResp);
        setJobPositionData(jobPositionDataResp);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return <div>{isLoading ? <LoadingComponent /> : getCashback2View()}</div>;
};

export default Cashback2Page;
