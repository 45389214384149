import { MySelectInputOptions } from '../components/FormControls/MySelectInput/types';

export enum TradeUnionTypesE {
  Firefighter = 1,
  ElectroMachinist = 2,
  JusticeSystem = 3,
  SolidarityPodbeskidzie = 4,
  Miners = 5,
  ZnpRybnik = 6,
  Opzz = 7,
  Znp = 8,
}
export enum PositionInTradeUnionE {
  PresidentZGZNP = 0,
  VicePresidentZGZNP = 1,
  PresidentRegionZNP = 2,
  PresidentBranchZNP = 3,
  PresidentCellZNP = 4,
  Member = 5,
}
export const TradeUnionOptions: MySelectInputOptions[] = [
  { value: TradeUnionTypesE.Firefighter, label: 'Związek zawodowy strażaków' },
  { value: TradeUnionTypesE.ElectroMachinist, label: 'Związek zawodowy elektromechaników' },
  { value: TradeUnionTypesE.JusticeSystem, label: 'Związek zawodowy pracowników wymiaru sprawiedliwości' },
  { value: TradeUnionTypesE.SolidarityPodbeskidzie, label: 'Solidarność Podbeskidzie' },
  { value: TradeUnionTypesE.Miners, label: 'Związek zawodowy górników' },
  { value: TradeUnionTypesE.Opzz, label: 'OPZZ' },
  { value: TradeUnionTypesE.Znp, label: 'ZNP' },
];
export const PositionInTradeUnionOptions: MySelectInputOptions[] = [
  { value: PositionInTradeUnionE.PresidentZGZNP, label: 'Prezes ZG ZNP' },
  { value: PositionInTradeUnionE.VicePresidentZGZNP, label: 'Wiceprezes ZG ZNP' },
  { value: PositionInTradeUnionE.PresidentRegionZNP, label: 'Prezes regionu ZNP' },
  { value: PositionInTradeUnionE.PresidentBranchZNP, label: 'Prezes oddziału ZNP' },
  { value: PositionInTradeUnionE.PresidentCellZNP, label: 'Prezes komórki ZNP' },
  { value: PositionInTradeUnionE.Member, label: 'Członek' },
];
