import React from 'react';
import * as styles from './styles';
import { useSwrAgent } from '../../../api/useSwrAgent';
import SkeletonTextLoader from '../../../components/Loadings/SkeletonTextLoader/SkeletonTextLoader';
import { Image } from 'react-bootstrap';
import { PathRoute } from '../../../constants/pathRoute/Route';
import LinkIcon from '../../../components/Icons/LinkIcon';
import { openLink } from '../../../utils/linkUtils';
import MyButton from '../../../components/Buttons/MyButton/MyButton';
import { useStore } from '../../../store/store';
import { history } from '../../../index';

const Cashback2InitialPage = (props: { goToNextStep: () => void; goToPreviousStep: () => void }) => {
  const { modalStore } = useStore();

  return (
    <div className={'tw-px-2'}>
      <div className='tw-bg-white'>
        <p className='tw-mt-4 tw-text-center tw-text-nau-green-dark'>
          Jest to program skierowany do ubezpieczonych w NAU pracowników oświaty. W ramach programu każdy uczestnik
          otrzymuje <strong>400 zł</strong> na Skarbonkę w Klubie NAU. Te środki można przeznaczyć na wybrane
          ubezpieczenia indywidualne zawarte za pośrednictwem NAU, zwiększając Twoje bezpieczeństwo i komfort życia.
        </p>
        <div className='tw-m-2 tw-flex tw-justify-center'>
          <Image src={'/assets/cashback2/main_first_block.png'} className='tw-m-2 tw-max-w-full' />
        </div>
        <div className='tw-p-2 tw-text-center tw-text-nau-green-dark'>
          <p>
            Dzięki podpisanej umowie kafeteryjnej Placówka zyskuje dostęp do szerokiej gamy benefitów, w tym kart
            sportowych, kart paliwowych, a przede wszystkim do Programu <strong>Skarbonka NAU</strong>.
          </p>
        </div>
        <div className='tw-m-2 tw-flex tw-justify-center'>
          <Image src={'/assets/cashback2/main_second_block.png'} className='tw-m-2 tw-max-w-full' />
        </div>
        <div className='tw-m-2 tw-flex tw-justify-center'>
          <Image src={'/assets/cashback2/main_third_block.png'} className='tw-m-2 tw-max-w-full' />
        </div>
        <div className='tw-p-4 tw-text-center tw-font-bold tw-text-nau-green-dark'>
          Zapoznaj się z przykładem, aby dowiedzieć się, ile możesz zyskać dzięki Programowi „Skarbonka NAU”.
        </div>
        <div className='tw-p-4 tw-pl-[52.75px]'>
          Środki z wirtualnej Skarbonki NAU możesz wykorzystać na zakup dowolnego ubezpieczenia indywidualnego zawartego
          za pośrednictwem NAU m. in.
          <ul className='tw-list-nau-green-light tw-mt-2 tw-list-disc'>
            <li>komunikacyjnego (OC, AC),</li>
            <li>majątkowego (dom, mieszkanie),</li>
            <li>turystycznego.</li>
          </ul>
        </div>
        <div className='tw-m-2 tw-flex'>
          <Image src={'/assets/cashback2/main_arrow_icon.svg'} className='tw-mt-6' style={{ zIndex: '1' }} />
          <div>
            <div
              className='tw-mt-4 tw-py-2 tw-font-bold tw-text-nau-green-dark'
              style={{ marginLeft: '-20px', marginRight: '-5px' }}>
              <span className='tw-text-[30px]'>15% - 25%</span> składki ubezpieczenia indywidualnego możesz sfinansować
              ze Skarbonki NAU
            </div>
            <div className='tw-mt-2 tw-flex tw-w-full tw-flex-col tw-items-start' style={{ marginLeft: '-20px' }}>
              <div className='tw-mt-2 tw-flex tw-w-full tw-flex-col tw-items-center'>
                <div className='tw-text-xs'>PRZYKŁAD</div>
                <div
                  style={{ boxShadow: '0px 3px 6px #00000029' }}
                  className='tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-between tw-rounded-lg tw-bg-white tw-px-[50px] tw-py-[20px]'>
                  <div>Skarbonka NAU</div>
                  <div className='tw-text-[36px] tw-font-bold tw-text-nau-green-light'>400 zł</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={'tw-mt-6 tw-flex tw-justify-center tw-gap-2'}>
          <MyButton onClick={() => props.goToPreviousStep()} variant={'gray'} className='tw-w-[50%]'>
            Wróć
          </MyButton>
          <MyButton onClick={() => props.goToNextStep()} variant={'primary'} className='tw-w-[50%]'>
            Dalej
          </MyButton>
        </div>
      </div>
    </div>
  );
};

export default Cashback2InitialPage;
