import React, { ReactNode, useEffect } from 'react';
import UserJobPositionDataForm from './UserJobPositionDataForm';
import { useSwrAgent } from '../../api/useSwrAgent';
import { twMerge } from '../../index';
import LoadingComponent from '../Loadings/LoadingComponent/LoadingComponent';
import { mutate } from 'swr';
import getUrls from '../../api/getUrls';
import UserPersonalDataForm from '../UserPersonalData/UserPersonalDataForm';
import { Container } from 'react-bootstrap';
import { useStore } from '../../store/store';

interface IUserJobPositionDataLayoutProps {
  children: ReactNode;
  wrapperClassName?: string;
}
const UserJobPositionLayout = ({ children, wrapperClassName }: IUserJobPositionDataLayoutProps) => {
  const { userStore } = useStore();
  const [loading, setLoading] = React.useState<boolean>(true);
  const { data: hasPersonalData, isLoading } = useSwrAgent().Users.HasUserPersonalData();
  const { data: hasJobPositionData, isLoading: isJobPositionDataLoading } =
    useSwrAgent().Users.HasUserJobPositionData();

  useEffect(() => {
    if (hasJobPositionData != undefined) {
      setLoading(true);
      userStore
        .setJobPositionData()
        .then((resp) => {
          if (resp.verified == true) {
            mutate(getUrls.User.hasUserJobPositionData);
          }
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [hasJobPositionData]);

  return (
    <>
      {isLoading || isJobPositionDataLoading || loading ? (
        <LoadingComponent />
      ) : !hasPersonalData ? (
        <div className={twMerge(wrapperClassName)}>
          <Container
            className='isEducationWorker d-flex flex-column align-items-center justify-content-center'
            style={{ textAlign: 'center', paddingTop: '2em', height: '100vh' }}>
            <UserPersonalDataForm
              onSave={async () => {
                mutate(getUrls.User.hasUserPersonalData);
              }}
            />
          </Container>
        </div>
      ) : (
        <div>
          {!hasJobPositionData && (
            <UserJobPositionDataForm
              onSave={async () => {
                mutate(getUrls.User.hasUserJobPositionData);
              }}
            />
          )}
          {hasJobPositionData && <div>{children}</div>}
        </div>
      )}
    </>
  );
};

export default UserJobPositionLayout;
