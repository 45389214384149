import React from 'react';
import { ICashback2BenefitInfo } from '../../../types/cashback2';
import { Cashback2InflowsTable } from './Cashback2InflowsTable';

interface ICashback2MoneyTableProps {
  cashback2BenefitInfo: ICashback2BenefitInfo;
}

const Cashback2MoneyTable = (props: ICashback2MoneyTableProps) => {
  const { cashback2BenefitInfo } = props;
  return (
    <div className='tw-w-full '>
      {cashback2BenefitInfo?.cashback2BalanceDto?.allocationsGrouped &&
      cashback2BenefitInfo?.cashback2BalanceDto.allocationsGrouped.length > 0 ? (
        <div>
          <Cashback2InflowsTable inflows={cashback2BenefitInfo?.cashback2BalanceDto.allocationsGrouped} />
          <div className='tw-m-2 tw-rounded-lg tw-bg-[#0AA9B0] tw-p-2 tw-font-semibold tw-text-white'>
            <span className='tw-ml-4 tw-mr-8'>
              {cashback2BenefitInfo.cashback2BalanceDto.allocationsGrouped
                .reduce((sum, item) => sum + (item.amount ?? 0), 0)
                .toFixed(2)}
            </span>{' '}
            Saldo razem
          </div>
        </div>
      ) : null}
      {cashback2BenefitInfo?.cashback2BalanceDto?.withdrawals &&
      cashback2BenefitInfo?.cashback2BalanceDto.withdrawals.length > 0 ? (
        <div className='tw-mt-8'>
          <p className='tw-mb-2 tw-font-semibold'>Zestawienie wypłat:</p>
          <div className={'tw-overflow-x-auto'}>
            <table className='tw-min-w-full tw-rounded-lg tw-bg-white tw-shadow-md'>
              <thead>
                <tr>
                  <th className='tw-border tw-border-gray-900 tw-bg-gray-200 tw-px-4 tw-py-2 tw-text-left tw-text-gray-700'>
                    Data wypłaty
                  </th>
                  <th className='tw-border tw-border-gray-900 tw-bg-gray-200 tw-px-4 tw-py-2 tw-text-left tw-text-gray-700'>
                    Kwota
                  </th>
                  <th className='tw-border tw-border-gray-900 tw-bg-gray-200 tw-px-4 tw-py-2 tw-text-left tw-text-gray-700'>
                    Cel wypłaty
                  </th>
                </tr>
              </thead>
              <tbody>
                {cashback2BenefitInfo?.cashback2BalanceDto.withdrawals.map((withdrawal, i) => (
                  <tr className='tw-border-t' key={i}>
                    <td className='tw-whitespace-nowrap tw-border tw-border-gray-900 tw-px-4 tw-py-2 tw-text-gray-700'>
                      {withdrawal.withdrawalDateFormatted}
                    </td>
                    <td className='tw-whitespace-nowrap tw-border tw-border-gray-900 tw-px-4 tw-py-2 tw-text-gray-700'>
                      {withdrawal.amount?.toFixed(2) ?? '-'} PLN
                    </td>
                    <td className='tw-border tw-border-gray-900 tw-px-4 tw-py-2 tw-text-gray-600'>
                      {withdrawal.withdrawalItemProductName}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Cashback2MoneyTable;
