export interface KlOrderFormM {
  customerId?: number;
  policyPositionId?: number;
  policyPersonUbrId?: number;
  variantId?: number;
  variant?: KlProduct;
  subvariantType: KlSubvariantType;
  startDate?: string;
  endDate?: string;
  country: string;
  insurerSportExtension: boolean;
  insurerDiseaseExtension: boolean;
  insurerDiseaseExtensionAvailable: boolean;
  insurerFirstName: string;
  insurerLastName: string;
  insurerPesel: string;
  insurerPhone: string;
  insurerEmail: string;
  insurerStreet: string;
  insurerHouseNumber: string;
  insurerFlatNumber: string;
  insurerPostCode: string;
  insurerCity: string;
  mainIsInsured?: boolean;
  positions: KlOrderPositionFormM[];
  availableVariant?: KlProduct;
  variantOptions: KlProduct[];
  [key: string]: any;
  approvalBonVoyage1: boolean;
  approvalBonVoyage2: boolean;
  approvalBonVoyage3: boolean;
  approvalBonVoyage4: boolean;
  approvalInterrisk1: boolean;
  approvalInterrisk2: boolean;
  approvalInterrisk3: boolean;
  approvalInterrisk4: boolean;
  approvalInterrisk5: boolean;
  approvalMarketing1: boolean;
  approvalMarketing2: boolean;
  approvalMarketingEmail: boolean;
  approvalMarketingSms: boolean;
  approvalMarketingPhone: boolean;
  approvalMarketingIvr: boolean;
  numberOfAdults: number;
  numberOfChildren: number;
  numberOfSeniors: number;
  wasBrInsuranceVerified?: boolean;
  brVerificationPesel?: string;
  brVerificationVariant?: KlProduct;
}

export enum KlPersonType {
  ADULT = 1,
  CHILD = 2,
  SENIOR = 3,
}

export enum KlSubvariantType {
  BASIC = 1,
  OPTIMAL = 2,
  ELASTIC = 3,
}

export interface KlOrderPositionFormM {
  policyPersonUbrId?: number;
  firstName: string;
  lastName: string;
  pesel: string;
  sportExtension: boolean;
  diseaseExtension: boolean;
  isMain: boolean;
  isVerified: boolean;
  diseaseExtensionAvailable: boolean;
  variantId: number;
  availableVariant?: KlProduct;
  variantOptions: KlProduct[];
  errorMessage?: string;
  personType?: KlPersonType;
  info?: string;
}
export interface KlInsuredPersonBRM {
  policyPositionId: number;
  customerId: number;
  policyPersonUbrId: number;
  policyPersonPzuId?: number;
  hasKlInThisYear: boolean;
  policyUnpaid: boolean;
  diseaseExtensionAvailable: boolean;
  variantOptions: KlProduct[];
  availableVariant: KlProduct;
}

export interface KlProduct {
  id: number;
  name: string;
  basePrice: number;
  sportPrice: number;
  diseasePrice: number;
  description: string;
}
export interface KlSaveOrderResponse {
  message: string;
  redirectLink: string | null;
}

export interface CheckInsuredPersonByPeselResponseDTO {
  isVerified: boolean;
  showRRModal: boolean;
}

export interface GetInsuredPersonVariantsModel {
  startDate?: string;
  endDate?: string;
  country: string;
  insurerPesel: string;
  pesels: string[];
}

export interface KlInsuredPersonVariantDTO {
  pesel: string;
  policyPositionId?: number;
  customerId?: number;
  policyPersonUbrId?: number;
  info?: string;
  variantId: number;
}
