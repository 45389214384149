import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import { useStore } from '../../../store/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { faCarSide } from '@fortawesome/free-solid-svg-icons';

interface VehicleInsuranceModalContentProps {
  title: string;
  message: string;
  additionalContent?: React.ReactNode;
}

const VehicleInsuranceModalContent: React.FC<VehicleInsuranceModalContentProps> = ({
  title,
  message,
  additionalContent,
}) => {
  const { modalStore } = useStore();
  const headerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  return (
    <motion.div animate='visible' className='tw-w-full' initial='hidden' variants={variants}>
      {/* Header Section with Icon */}
      <div
        className={twMerge(
          'tw-rounded-t-[24px] tw-bg-gradient-to-br tw-from-[#0bc268] tw-to-[#00635c] tw-p-4 tw-text-white md:tw-rounded-t-[36px] md:tw-p-8',
        )}
        ref={headerRef}>
        <div className='tw-flex tw-flex-col tw-items-center tw-gap-4 md:tw-flex-row md:tw-gap-0'>
          <motion.div
            animate={{ scale: 1 }}
            className='tw-rounded-full tw-bg-white/10 tw-p-3 md:tw-p-4'
            initial={{ scale: 0 }}
            transition={{ delay: 0.2, type: 'spring', stiffness: 200 }}>
            <FontAwesomeIcon className='tw-text-2xl md:tw-text-3xl' icon={faCarSide} />
          </motion.div>
          <motion.h2
            animate={{ opacity: 1, x: 0 }}
            className='tw-text-center tw-text-lg tw-font-bold md:tw-ml-6 md:tw-text-left md:tw-text-2xl'
            initial={{ opacity: 0, x: -20 }}
            transition={{ delay: 0.3 }}>
            {title}
          </motion.h2>
        </div>
      </div>

      {/* Content Section */}
      <motion.div
        className='tw-rounded-b-[24px] tw-bg-white tw-shadow-lg md:tw-rounded-b-[36px]'
        ref={contentRef}
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0, transition: { delay: 0.4 } },
        }}>
        <div className='tw-p-4 md:tw-p-8'>
          {/* Main Message */}
          <p className='tw-mb-4 tw-text-center tw-text-base tw-leading-relaxed tw-text-gray-700 md:tw-mb-6 md:tw-text-lg'>
            {message}
          </p>

          {/* Additional Content */}
          {additionalContent && (
            <motion.div
              className='tw-mt-4 md:tw-mt-6'
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0, transition: { delay: 0.5 } },
              }}>
              {additionalContent}
            </motion.div>
          )}
        </div>
      </motion.div>
    </motion.div>
  );
};

export default VehicleInsuranceModalContent;
