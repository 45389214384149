const Article = {
  getArticle: '/v1/articles/articles',
};

const BirthdayCodes = {
  getUserBirthdayCodeCampaigns: '/BirthdayCode/GetUserBirthdayCodeCampaigns',
  getUserBirthdayCodes: '/BirthdayCode/GetUserBirthdayCodes',
};

const Cashback = {
  getCashbackBenefitInfo: '/Cashback/GetCashbackBenefitInfo',
  getCashbackBalance: '/Cashback/GetCashbackBalance',
};

const Cashback2 = {
  getCashback2BenefitInfo: '/Cashback2/GetCashback2BenefitInfo',
  getCashback2Balance: '/Cashback2/GetCashback2Balance',
};
const DiscountCodes = {
  checkHasInstitutionalDiscountCodesByUser: '/DiscountCode/CheckHasInstitutionalDiscountCodesByUser',
  getUserDiscountCodeContractStatus: '/DiscountCode/GetDiscountCodeContractStatus',
  getAllUserDiscountCodeContracts: '/DiscountCode/GetAllUserDiscountCodeContractStatuses',
  getUserDiscountCodes: '/DiscountCode/GetUserDiscountCodes',
  checkHasNotSettledSettlements: '/DiscountCode/CheckHasNotSettledSettlements',
};

const User = {
  getUserAvailableBenefits: '/User/getUserAvailableBenefit',
  getUserMarketingData: '/User/GetUserMarketingData',
  hasUserPersonalData: '/User/HasUserPersonalData',
  hasUserJobPositionData: '/User/HasUserJobPositionData',
  isUserAssignedToInstitutionAsDirector: '/User/IsUserAssignedToInstitutionAsDirector',
  getUserProfileNew: '/User/GetUserProfileNew',
};

const Payments = {
  getUserPayments: '/Payment/GetUserPayments',
};

const Wallet = {
  getWalletContractStatus: '/Wallet/GetWalletContractStatus',
};

const CinemaVouchers = {
  getUserCinemaVouchers: '/CinemaVoucher/GetUserCinemaVouchers',
};

const SportCards = {
  getSportCardsForUser: '/SportCard/GetSportCardsForUser',
  checkSportCardIsAvailableInInstitution: '/SportCard/CheckSportCardIsAvailableInInstitution',
};
const InstitutionAdmin = {
  getFundDetails: '/InstitutionAdmin/GetFundDetails',
  getEmployees: '/InstitutionAdmin/GetEmployees',
  getFundOrders: '/InstitutionAdmin/GetFundOrders',
  getInstitutionSettlements: '/InstitutionAdmin/GetInstitutionSettlements',
  getEmployeeSettlementsForSettlement: '/InstitutionAdmin/GetEmployeeSettlementsForSettlement',
  getProductSettlementsForSettlement: '/InstitutionAdmin/GetProductSettlementsForSettlement',
  getEmployeeProductSettlements: '/InstitutionAdmin/GetEmployeeProductSettlements',
  hasAcceptedFundRegulationsAuthorization: '/InstitutionAdmin/HasAcceptedFundRegulationsAuthorization',
};

const NauMobile = {
  getAvailablePhonesModel: '/NauMobile/GetAvailablePhonesModel',
  getAvailableProviderContracts: '/NauMobile/GetAvailableProviderContractsModel?providerTypeId=',
};

const Kl = {
  getAllVariant: '/Kl/GetAllVariants',
};

const AiOffice = {
  getNau24Offer: '/AiOffice/GetNau24VehicleInsuranceOffers?vehicleCalculationId=',
  getUserVehicles: '/AiOffice/GetUserVehicles',
  downloadVehicleInsuranceFile: '/AiOffice/DownloadVehicleInsuranceFile',
};

const getUrls = {
  Article,
  BirthdayCodes,
  Cashback,
  Cashback2,
  DiscountCodes,
  CinemaVouchers,
  User,
  Payments,
  Wallet,
  SportCards,
  NauMobile,
  InstitutionAdmin,
  Kl,
  AiOffice,
};

export default getUrls;
