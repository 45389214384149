import React from 'react';
import { observer } from 'mobx-react-lite';

import moment from 'moment';
import CustomerDashboard from '../CustomerDashboard/CustomerDashboard';
import LoadingComponent from '../../components/Loadings/LoadingComponent/LoadingComponent';
import SubpageHeader from '../../components/SubpageHeader/SubpageHeader';
import { twMerge } from 'tailwind-merge';
import { Bike, Car, ChevronDown, Download, Shield } from 'lucide-react';
import { VehicleTypeIdE } from '../InsurancePurchaseForms/VehicleInsurance/vehicleInsuranceEnum';
import { useSwrAgent } from '../../api/useSwrAgent';
import { UserVehicleFromSopDto, VehicleInsurancePolicyDataFromSopModel } from '../../types/vehicle';
import agent from '../../api/agent';
import FileHelper from '../../helpers/FileHelper';
import MyButton from '../../components/Buttons/MyButton/MyButton';

// Extracted components for reusability
const VehicleHeader: React.FC<{ vehicle: UserVehicleFromSopDto }> = ({ vehicle }) => (
  <div className='tw-mb-3 tw-flex tw-items-center'>
    <div className='tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full tw-bg-blue-100'>
      {getVehicleTypeIcon(vehicle.vehicleTypeId)}
    </div>
    <div className='tw-ml-4'>
      <h2 className='tw-text-xl tw-font-semibold'>{vehicle.brand}</h2>
      <p className='tw-text-gray-600'>{vehicle.model}</p>
    </div>
  </div>
);

const VehicleDetails: React.FC<{ vehicle: UserVehicleFromSopDto }> = ({ vehicle }) => (
  <div className='tw-flex-1 tw-border-t tw-border-gray-200 tw-pt-3'>
    <div className='tw-mb-2 tw-flex tw-justify-between'>
      <span className='tw-text-gray-600'>Rejestracja:</span>
      <span className='tw-font-medium'>{vehicle.platesNumber}</span>
    </div>
    <div className='tw-mb-2 tw-flex tw-justify-between'>
      <span className='tw-text-gray-600'>Rok produkcji:</span>
      <span className='tw-font-medium'>{vehicle.productionYear}</span>
    </div>
  </div>
);

const PolicyItem: React.FC<{ policy: VehicleInsurancePolicyDataFromSopModel }> = ({ policy }) => {
  // Calculate status and get appropriate colors
  const isExpired = policy.policyEndDate ? moment(policy.policyEndDate, 'YYYY-MM-DD').isBefore(moment()) : false;
  const isExpiringSoon = !isExpired && isInsuranceExpiringSoon(policy.policyEndDate);
  const [isPolicyFileDownloading, setIsPolicyFileDownloading] = React.useState(false);
  const [isProductCardFileDownloading, setIsProductCardFileDownloading] = React.useState(false);

  const downloadPolicyFile = async (guid: string) => {
    setIsPolicyFileDownloading(true);
    try {
      const response = await agent.AiOffice.downloadVehicleInsuranceFile(guid);
      await FileHelper.downloadViaBrowser(response.fileName, response.fileContent, response.fileMime);
    } catch (error) {
      console.error(error);
    } finally {
      setIsPolicyFileDownloading(false);
    }
  };

  const downloadProductCardFile = async (guid: string) => {
    setIsProductCardFileDownloading(true);
    try {
      const response = await agent.AiOffice.downloadVehicleInsuranceFile(guid);
      await FileHelper.downloadViaBrowser(response.fileName, response.fileContent, response.fileMime);
    } finally {
      setIsProductCardFileDownloading(false);
    }
  };
  // Determine status badge color
  const statusColor = isExpired
    ? 'tw-bg-red-100 tw-text-red-800'
    : isExpiringSoon
      ? 'tw-bg-amber-100 tw-text-amber-800'
      : 'tw-bg-green-100 tw-text-green-800';

  // Determine status text
  const statusText = isExpired ? 'Wygasło' : isExpiringSoon ? 'Wygasa wkrótce' : 'Aktywne';

  return (
    <div className='tw-mb-4 tw-rounded-lg tw-border tw-border-gray-200 tw-bg-white tw-p-4 tw-shadow-sm tw-transition-all hover:tw-shadow'>
      {/* Policy Header with Company and Status */}
      <div className='tw-mb-3 tw-flex tw-items-center tw-justify-between'>
        <div className='tw-flex tw-items-center'>
          <div className='tw-mr-3 tw-rounded-full tw-bg-blue-50 tw-p-2'>
            <Shield className='tw-h-5 tw-w-5 tw-text-blue-600' />
          </div>
          <h3 className='tw-font-medium tw-text-gray-900'>{policy.vehicleInsuranceCompanyName}</h3>
        </div>
        <div className={`tw-rounded-full tw-px-3 tw-py-1 tw-text-xs tw-font-medium ${statusColor}`}>{statusText}</div>
      </div>

      {/* Order Date */}
      {policy.policyOrderDate && (
        <div className='tw-mb-2 tw-flex tw-justify-between'>
          <span className='tw-text-sm tw-text-gray-600'>Data zamówienia:</span>

          <span className='tw-text-sm tw-font-medium tw-text-gray-900'>
            {policy.policyOrderDate ? moment(policy.policyOrderDate, 'YYYY-MM-DD').format('DD.MM.YYYY') : '-'}
          </span>
        </div>
      )}

      {/* Policy Details */}
      <div className='tw-mb-3 tw-space-y-2'>
        <div className='tw-flex tw-justify-between'>
          <span className='tw-text-sm tw-text-gray-600'>Numer polisy:</span>
          <span className='tw-text-sm tw-font-medium tw-text-gray-900'>{policy.policyNumber || 'Na polisie'}</span>
        </div>
        <div className='tw-flex tw-justify-between'>
          <span className='tw-text-sm tw-text-gray-600'>Numer konta do zapłaty:</span>
          <span className='tw-text-sm tw-font-medium tw-text-gray-900'>{policy.bankAccountNumber || 'Na polisie'}</span>
        </div>
        <div className='tw-flex tw-justify-between'>
          <span className='tw-text-sm tw-text-gray-600'>Ważna od:</span>
          <span className='tw-text-sm tw-font-medium tw-text-gray-900'>
            {policy.policyStartDate ? moment(policy.policyStartDate, 'YYYY-MM-DD').format('DD.MM.YYYY') : 'Na polisie'}
          </span>
        </div>
        <div className='tw-flex tw-justify-between'>
          <span className='tw-text-sm tw-text-gray-600'>Ważna do:</span>
          <span
            className={`tw-text-sm tw-font-medium ${isExpiringSoon ? 'tw-text-amber-600' : isExpired ? 'tw-text-red-600' : 'tw-text-gray-900'}`}>
            {policy.policyEndDate ? moment(policy.policyEndDate, 'YYYY-MM-DD').format('DD.MM.YYYY') : 'Na polisie'}
          </span>
        </div>
      </div>

      {/* Warning Message */}
      {isExpiringSoon && (
        <div className='tw-mb-3 tw-flex tw-items-center tw-rounded-md tw-bg-amber-50 tw-p-2 tw-text-sm tw-text-amber-700'>
          <svg
            className='tw-mr-2 tw-h-4 tw-w-4'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              clipRule='evenodd'
              d='M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z'
              fillRule='evenodd'
            />
          </svg>
          {getInsuranceStatusText(policy.policyEndDate)}
        </div>
      )}

      {/* Expired Message */}
      {isExpired && (
        <div className='tw-mb-3 tw-flex tw-items-center tw-rounded-md tw-bg-red-50 tw-p-2 tw-text-sm tw-text-red-700'>
          <svg
            className='tw-mr-2 tw-h-4 tw-w-4'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              clipRule='evenodd'
              d='M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z'
              fillRule='evenodd'
            />
          </svg>
          Ubezpieczenie wygasło
        </div>
      )}

      {/* Action Button */}
      <MyButton
        className='tw-w-full'
        isLoading={isPolicyFileDownloading}
        onClick={() => downloadPolicyFile(policy.policyFileGuid)}
        variant='blue'>
        <>
          <Download className='tw-mr-1 tw-h-4 tw-w-4' />
          Pobierz polisę
        </>
      </MyButton>

      <MyButton
        className='tw-mt-2 tw-w-full'
        isLoading={isProductCardFileDownloading}
        onClick={() => downloadProductCardFile(policy.productCardFileGuid)}
        variant='blue'>
        <>
          <Download className='tw-mr-1 tw-h-4 tw-w-4' />
          Pobierz kartę produktu
        </>
      </MyButton>
    </div>
  );
};

const PoliciesList: React.FC<{ policies: VehicleInsurancePolicyDataFromSopModel[] }> = ({ policies }) => {
  // If we have multiple policies, use a tab system
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  // Find the policy closest to expiration that's not expired
  React.useEffect(() => {
    const now = moment();
    let closestToExpirationIndex = 0;
    let closestDays = Number.MAX_SAFE_INTEGER;

    policies.forEach((policy, index) => {
      if (!policy.policyEndDate) return;

      const expirationDate = moment(policy.policyEndDate, 'YYYY-MM-DD');
      if (expirationDate.isBefore(now)) return; // Skip expired policies

      const daysUntilExpiration = expirationDate.diff(now, 'days');
      if (daysUntilExpiration < closestDays) {
        closestDays = daysUntilExpiration;
        closestToExpirationIndex = index;
      }
    });

    setActiveTabIndex(closestToExpirationIndex);
  }, [policies]);

  return (
    <div className='tw-flex-1 tw-overflow-hidden'>
      <div className='tw-mb-3 tw-text-lg tw-font-medium tw-text-gray-700'>Polisy ubezpieczeniowe</div>

      {policies.length > 1 ? (
        <div className={'tw-w-full'}>
          {/* Tabs navigation */}
          <div className='tw-mb-2 tw-flex tw-w-full tw-overflow-x-auto tw-border-b tw-border-gray-200'>
            {policies.map((policy, index) => {
              const isExpired = policy.policyEndDate
                ? moment(policy.policyEndDate, 'YYYY-MM-DD').isBefore(moment())
                : false;
              const isExpiringSoon = !isExpired && isInsuranceExpiringSoon(policy.policyEndDate);

              return (
                <button
                  className={`tw-mr-4 tw-flex tw-flex-col tw-whitespace-nowrap tw-border-b-2 tw-py-2 tw-text-sm tw-font-medium ${
                    activeTabIndex === index
                      ? 'tw-border-blue-500 tw-text-blue-600'
                      : 'tw-border-transparent tw-text-gray-500 hover:tw-border-gray-300 hover:tw-text-gray-700'
                  }`}
                  key={policy.policyNumber || index}
                  onClick={() => setActiveTabIndex(index)}>
                  <span className='tw-flex tw-items-center'>
                    {policy.vehicleInsuranceCompanyName}
                    {isExpired && (
                      <span className='tw-ml-2 tw-inline-block tw-h-2 tw-w-2 tw-rounded-full tw-bg-red-500'></span>
                    )}
                    {isExpiringSoon && (
                      <span className='tw-ml-2 tw-inline-block tw-h-2 tw-w-2 tw-rounded-full tw-bg-amber-500'></span>
                    )}
                  </span>
                  {policy.policyOrderDate && (
                    <span className='tw-text-xs tw-text-gray-500'>
                      {policy.policyOrderDate ? moment(policy.policyOrderDate, 'YYYY-MM-DD').format('DD.MM.YYYY') : '-'}
                    </span>
                  )}
                </button>
              );
            })}
          </div>

          {/* Active policy details */}
          <PolicyItem policy={policies[activeTabIndex]} />
        </div>
      ) : (
        <PolicyItem policy={policies[0]} />
      )}
    </div>
  );
};

const getVehicleTypeIcon = (vehicleTypeId: VehicleTypeIdE) => {
  switch (vehicleTypeId) {
    case VehicleTypeIdE.CAR:
      return <Car className={'tw-h-6 tw-w-6'} />;
    case VehicleTypeIdE.MOTORCYCLE:
      return <Bike className={'tw-h-6 tw-w-6'} />;
    default:
      return null;
  }
};

// Custom Accordion Component
const SimpleAccordion: React.FC<{
  title: string;
  children: React.ReactNode;
  badgeCount?: number;
  hasPoliciesExpiring?: boolean;
  hasPoliciesExpired?: boolean;
}> = ({ title, children, badgeCount, hasPoliciesExpiring, hasPoliciesExpired }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className='tw-w-full'>
      <button
        className='tw-flex tw-w-full tw-items-center tw-justify-between tw-rounded-md tw-bg-gray-50 tw-p-3 tw-font-medium tw-text-gray-700'
        onClick={() => setIsOpen(!isOpen)}>
        <div className='tw-flex tw-items-center'>
          {title}
          {badgeCount && badgeCount > 0 && (
            <span className='tw-ml-2 tw-rounded-full tw-bg-blue-100 tw-px-2 tw-py-0.5 tw-text-xs tw-font-medium tw-text-blue-800'>
              {badgeCount}
            </span>
          )}
          {hasPoliciesExpired && (
            <span className='tw-ml-2 tw-inline-block tw-h-2.5 tw-w-2.5 tw-rounded-full tw-bg-red-500'></span>
          )}
          {hasPoliciesExpiring && !hasPoliciesExpired && (
            <span className='tw-ml-2 tw-inline-block tw-h-2.5 tw-w-2.5 tw-rounded-full tw-bg-amber-500'></span>
          )}
        </div>
        <ChevronDown
          className={`tw-h-5 tw-w-5 tw-transform tw-text-gray-500 tw-transition-transform tw-duration-200 ${
            isOpen ? 'tw-rotate-180' : ''
          }`}
        />
      </button>
      {isOpen && <div className='tw-p-1 tw-py-3'>{children}</div>}
    </div>
  );
};

const UserVehicles = observer(() => {
  const { data: vehicles, mutate, isLoading } = useSwrAgent().AiOffice.GetUserVehicles();

  return (
    <CustomerDashboard>
      <LoadingComponent visible={isLoading} />
      <div className='tw-container tw-mx-auto tw-px-4 tw-py-6'>
        <SubpageHeader
          headerClassName={twMerge('tw-text-xl lg:tw-text-2xl')}
          title={() => (
            <div className='tw-flex tw-items-center tw-justify-center tw-gap-2'>
              <div className={'tw-flex tw-justify-center'}>
                <Car className={' tw-h-10 tw-w-10'} />
              </div>
              <div className={'tw-flex tw-items-center tw-justify-center tw-gap-2'}>Moje pojazdy</div>
            </div>
          )}
        />
        <div className='tw-rounded-b-lg tw-bg-white tw-p-6 tw-shadow'>
          {vehicles?.length === 0 && !isLoading ? (
            <div className='tw-rounded-lg tw-bg-white tw-p-6 tw-text-center '>
              <p className='tw-text-gray-600'>Nie masz żadnych pojazdów w systemie.</p>
            </div>
          ) : (
            <div className='tw-grid tw-grid-cols-1  tw-gap-6'>
              {vehicles?.map((vehicle) => (
                <div
                  className='tw-full tw-overflow-hidden tw-rounded-lg tw-border tw-border-gray-300 tw-bg-white tw-shadow'
                  key={vehicle.id}>
                  <div className='tw-flex tw-flex-col tw-gap-4 tw-p-4 md:tw-flex-row '>
                    {/* Vehicle header with icon, brand and model */}
                    <div className={'md:tw-min-w-[250px]'}>
                      <VehicleHeader vehicle={vehicle} />
                      <VehicleDetails vehicle={vehicle} />
                    </div>

                    {/* Desktop: Side-by-side layout */}
                    <div className='tw-hidden tw-flex-1 tw-flex-col tw-overflow-hidden md:tw-flex md:tw-flex-row md:tw-gap-4'>
                      {vehicle.policies && vehicle.policies.length > 0 && <PoliciesList policies={vehicle.policies} />}
                    </div>

                    {/* Mobile: Stacked layout with accordion */}
                    <div className='md:tw-hidden'>
                      {vehicle.policies && vehicle.policies.length > 0 && (
                        <div className='tw-border-t tw-border-gray-200 tw-pt-3'>
                          {/* Check for expiring and expired policies for badge indicators */}
                          {(() => {
                            const hasPoliciesExpiring = vehicle.policies.some(
                              (policy) =>
                                (policy.policyEndDate
                                  ? !moment(policy.policyEndDate, 'YYYY-MM-DD').isBefore(moment())
                                  : false) && isInsuranceExpiringSoon(policy.policyEndDate),
                            );
                            const hasPoliciesExpired = vehicle.policies.some((policy) =>
                              policy.policyEndDate
                                ? moment(policy.policyEndDate, 'YYYY-MM-DD').isBefore(moment())
                                : false,
                            );

                            return (
                              <SimpleAccordion
                                badgeCount={vehicle.policies.length}
                                hasPoliciesExpired={hasPoliciesExpired}
                                hasPoliciesExpiring={hasPoliciesExpiring}
                                title='Polisy ubezpieczeniowe'>
                                {vehicle.policies.length > 1 ? (
                                  // For multiple policies, use a condensed mobile-friendly list
                                  <div>
                                    {vehicle.policies.map((policy, index) => {
                                      const isExpired = policy.policyEndDate
                                        ? moment(policy.policyEndDate, 'YYYY-MM-DD').isBefore(moment())
                                        : false;
                                      const isExpiringSoon =
                                        !isExpired && isInsuranceExpiringSoon(policy.policyEndDate);

                                      // Determine status badge color
                                      const statusColor = isExpired
                                        ? 'tw-bg-red-100 tw-text-red-800'
                                        : isExpiringSoon
                                          ? 'tw-bg-amber-100 tw-text-amber-800'
                                          : 'tw-bg-green-100 tw-text-green-800';

                                      // Determine status text
                                      const statusText = isExpired
                                        ? 'Wygasło'
                                        : isExpiringSoon
                                          ? 'Wygasa wkrótce'
                                          : 'Aktywne';

                                      return <PolicyItem key={policy.policyNumber} policy={policy} />;
                                    })}
                                  </div>
                                ) : (
                                  // For a single policy, use the normal policy item
                                  <PolicyItem policy={vehicle.policies[0]} />
                                )}
                              </SimpleAccordion>
                            );
                          })()}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </CustomerDashboard>
  );
});

// Helper function to check if insurance is expiring soon (within 30 days)
const isInsuranceExpiringSoon = (date?: string) => {
  if (!date) return false;
  const insuranceDate = moment(date, 'YYYY-MM-DD');
  const now = moment();
  const daysUntilExpiration = insuranceDate.diff(now, 'days');
  return daysUntilExpiration <= 30 && daysUntilExpiration >= 0;
};

// Helper function to get days until insurance expiration or text if expired
const getInsuranceStatusText = (date?: string) => {
  if (!date) return '';
  const insuranceDate = moment(date, 'YYYY-MM-DD');
  const now = moment();
  const daysUntilExpiration = insuranceDate.diff(now, 'days');

  if (daysUntilExpiration < 0) {
    return 'Ubezpieczenie wygasło';
  } else {
    return `${daysUntilExpiration} dni do końca ubezpieczenia`;
  }
};

export default UserVehicles;
