import React, { useEffect, useState } from 'react';
import { Nau24OfferPdfVehicleInsuranceDataModel } from './types';
import { Landmark, PiggyBank, Wallet, X } from 'lucide-react';
import MyButton from '../../../components/Buttons/MyButton/MyButton';
import { twMerge } from '../../../index';
import { useSwrAgent } from '../../../api/useSwrAgent';
import { LoadingCarBackdrop } from './LoadingCarBackdrop';
import { getInsuranceCompanyLogo, identifyInsuranceCompanyE } from './utils';
import { useStore } from '../../../store/store';
import { VehicleInsuranceOfferSelection } from './VehicleInsuranceOfferSelection';
import { MySpinner } from '../../../components/Loadings/Spinner';
import { AnimatePresence, motion } from 'framer-motion';
import { VehicleInsuranceFormStepE, VehicleInsuranceStatusId } from './vehicleInsuranceEnum';
import { useVehicleInsuranceStore } from '../../../store/VehicleInsurance/vehicleInsuranceStore';
import VehicleInsuranceModalContent from './VehicleInsuranceModalContent';

const piggyBankBalance = 500;

interface IVehicleInsuranceOffersProps {
  calculationId: number;
}

export function VehicleInsuranceOffers(props: IVehicleInsuranceOffersProps) {
  const { modalStore } = useStore();
  const { setCurrentStep } = useVehicleInsuranceStore();
  const [selectedOffer, setSelectedOffer] = useState<Nau24OfferPdfVehicleInsuranceDataModel | null>(null);

  const { data: offersData, isLoading, mutate } = useSwrAgent().AiOffice.GetNau24Offer(props.calculationId);

  const handleOfferSelect = (offer: Nau24OfferPdfVehicleInsuranceDataModel) => {
    setSelectedOffer(offer);
  };

  const handleCancelSelection = () => {
    setSelectedOffer(null);
  };

  const getNumberFromString = (str: string): number => {
    return parseFloat(str.replace(/\s/g, '').replace(',', '.'));
  };

  const getDiscountedPrice = (price: string): number => {
    const discountAmount = Math.min(piggyBankBalance, getNumberFromString(price) * 0.25);
    return Math.max(getNumberFromString(price) - discountAmount, 0);
  };

  const shouldShowLoading =
    isLoading || (offersData?.calculationEnded === false && offersData?.vehicleInsuranceOffers?.length === 0);

  const itemVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
    },
  };

  // Get filtered offers - either all offers or just the selected one
  const getFilteredOffers = () => {
    if (!offersData?.vehicleInsuranceOffers) return [];

    const sortedOffers = [...offersData.vehicleInsuranceOffers].sort(
      (a, b) =>
        parseFloat(a.price.replace(/\s/g, '').replace(',', '.')) -
        parseFloat(b.price.replace(/\s/g, '').replace(',', '.')),
    );

    if (selectedOffer) {
      return sortedOffers.filter((offer) => offer.name === selectedOffer.name);
    }

    return sortedOffers;
  };

  // Loading message component
  const LoadingMoreOffersMessage = () => {
    if (offersData?.calculationEnded === false && offersData?.vehicleInsuranceOffers?.length > 0 && !selectedOffer) {
      return (
        <motion.div
          animate='visible'
          className='tw-transform tw-rounded-xl tw-border tw-border-gray-300 tw-bg-white tw-p-6 tw-shadow-lg tw-transition-transform'
          initial='hidden'
          key='loading-more'
          variants={itemVariants}>
          <div className='tw-flex tw-items-center tw-justify-center tw-gap-2 tw-text-center tw-text-lg tw-font-semibold tw-text-nau-kids-gray'>
            Trwa ładowanie pozostałych ofert
            <MySpinner className={'tw-flex-none tw-text-nau-green-light'} />
          </div>
        </motion.div>
      );
    }
    return null;
  };

  useEffect(() => {
    if (offersData?.calculationStatusId === VehicleInsuranceStatusId.MaxSessionsReached) {
      modalStore.openModal(
        <VehicleInsuranceModalContent
          additionalContent={
            <MyButton
              onClick={() => {
                modalStore.closeModal();
              }}
              type={'button'}
              variant='primary'>
              Wróć
            </MyButton>
          }
          message='Prosimy o cierpliwość i ponowną próbę za chwilę.'
          title='Z naszego serwisu korzysta obecnie duża liczba użytkowników.'
        />,
        'lg',
        () => <></>,
        undefined,
        'tw-p-0',
      );
      setCurrentStep(VehicleInsuranceFormStepE.SUMMARY);
    }
  }, [offersData]);

  return (
    <>
      <LoadingCarBackdrop
        isLoading={shouldShowLoading}
        texts={[
          ...Array.from({ length: 10 }, (_, i) => `Zbieranie danych towarzystwa ${i + 1}`),
          ...Array(100)
            .fill([
              'Przetwarzanie informacji...',
              'Dobieranie najlepszych ofert...',
              'Oczekiwanie na odpowiedź...',
              'Analiza ubezpieczenia...',
              'Weryfikacja danych...',
            ])
            .flat(),
        ]}
      />
      <div className={'tw-relative tw-min-h-[150px]'}>
        {!shouldShowLoading && (
          <>
            <div className=' tw-mb-6  tw-rounded-md tw-bg-gradient-to-r tw-from-nau-green-light tw-to-nau-green-light tw-p-2 tw-text-center tw-text-lg  tw-text-white'>
              <div className={'tw-flex tw-items-center tw-justify-center'}>
                <PiggyBank className={'tw-inline tw-h-12 tw-w-12'} />
              </div>
              <span className='tw-font-semibold'>Saldo skarbonki NAU:</span>

              <div className={'tw-flex tw-items-center tw-justify-center tw-font-bold'}>{piggyBankBalance} zł</div>
            </div>
            <div className='tw-mb-4 tw-text-center tw-text-2xl tw-font-bold tw-text-nau-kids-gray'>
              {selectedOffer ? 'Wybrana oferta' : 'Przygotowane oferty'}
            </div>
          </>
        )}

        <div className='tw-grid tw-grid-cols-1 tw-gap-6'>
          {offersData?.calculationEnded && offersData?.vehicleInsuranceOffers?.length === 0 && (
            <div className='tw-text-center tw-text-lg tw-font-semibold tw-text-nau-kids-gray'>
              Brak ofert dla podanego pojazdu
            </div>
          )}

          <div className='tw-grid tw-grid-cols-1 tw-gap-6'>
            <AnimatePresence mode='popLayout'>
              {getFilteredOffers().map((item) => {
                const discountedPrice = getDiscountedPrice(item.price);
                const hasInstallment = item.installment && item.installment !== '-';
                const isSelected = selectedOffer && selectedOffer.name === item.name;

                return (
                  <motion.div
                    animate='visible'
                    className='tw-transform tw-rounded-xl tw-border tw-border-gray-300 tw-bg-white tw-p-6 tw-shadow-lg tw-transition-transform'
                    exit={{ opacity: 0 }}
                    initial='hidden'
                    key={item.name}
                    variants={itemVariants}>
                    <div className='tw-relative'>
                      {isSelected && (
                        <button
                          className='tw-absolute tw-right-0 tw-top-0 tw-rounded-full tw-bg-gray-200 tw-p-1 tw-text-gray-600 hover:tw-bg-gray-300'
                          onClick={handleCancelSelection}>
                          <X className='tw-h-5 tw-w-5' />
                        </button>
                      )}

                      <div className='tw-mb-4 tw-flex tw-flex-col tw-items-center tw-justify-between sm:tw-flex-row'>
                        <h3 className='tw-text-xl tw-font-semibold tw-text-nau-kids-gray sm:tw-text-2xl'>
                          {item.name}
                        </h3>
                        {getInsuranceCompanyLogo(item.name) && (
                          <img
                            alt={`${item.name} logo`}
                            className='tw-mr-3 tw-h-[90px] tw-w-[90px] tw-rounded-md tw-bg-white tw-object-contain'
                            src={getInsuranceCompanyLogo(item.name)}
                          />
                        )}
                      </div>

                      <div className='tw-mb-4'>
                        <div className='tw-flex tw-items-center tw-text-nau-kids-gray'>
                          <Wallet className='tw-mr-1 tw-h-5 tw-w-5' />
                          <span className='tw-font-bold'>Cena:</span>
                          <span className={twMerge('tw-ml-1', piggyBankBalance > 0 && 'tw-line-through')}>
                            {item.price} zł
                          </span>
                        </div>
                        {piggyBankBalance > 0 && (
                          <div className='tw-mt-1 tw-flex tw-items-center tw-text-lg tw-text-green-500'>
                            <span className='tw-font-bold'>Ze skarbonką NAU:</span>
                            <span className='tw-ml-1 tw-font-bold'>{discountedPrice} zł</span>
                          </div>
                        )}
                      </div>

                      {hasInstallment && (
                        <div className='tw-mb-4'>
                          <div className='tw-flex tw-items-center tw-text-nau-kids-gray'>
                            <Landmark className='tw-mr-1 tw-h-5 tw-w-5' />
                            <span className='tw-font-bold'>Lub 12 rat miesięcznych po:</span>
                            <span className='tw-ml-1'>{item.installment} zł</span>
                          </div>
                        </div>
                      )}

                      {isSelected ? (
                        <div className='tw-mt-4 tw-border-t tw-border-gray-200 tw-pt-4'>
                          <VehicleInsuranceOfferSelection
                            basePrice={getNumberFromString(item.price)}
                            insuranceVehicleCompany={identifyInsuranceCompanyE(item.name)}
                            offerSopId={item.offerSopId}
                            onCancel={handleCancelSelection}
                            reducedPrice={getDiscountedPrice(item.price)}
                            showAsInline={true}
                            vehicleInsuranceCalculationId={offersData?.vehicleInsuranceId ?? 0}
                          />
                        </div>
                      ) : (
                        <div className='tw-flex tw-justify-end tw-text-nau-kids-gray'>
                          <MyButton
                            isLoading={offersData?.calculationEnded === false}
                            onClick={() => handleOfferSelect(item)}
                            type={'button'}
                            variant='primary'>
                            Wybieram
                          </MyButton>
                        </div>
                      )}
                    </div>
                  </motion.div>
                );
              })}
            </AnimatePresence>

            <LoadingMoreOffersMessage />
          </div>
        </div>
      </div>
    </>
  );
}
