// that is for strings like '27KM (20kW)'
import { VehicleInsuranceCompany } from './vehicleInsuranceEnum';

export function extractKmAndKw(input?: string): { km?: number; kw?: number } {
  if (!input) {
    return { km: undefined, kw: undefined };
  }
  const kmMatch = input.match(/(\d+)\s*KM/);
  const kwMatch = input.match(/(\d+)\s*kW/i);
  return {
    km: kmMatch ? parseInt(kmMatch[1], 10) : undefined,
    kw: kwMatch ? parseInt(kwMatch[1], 10) : undefined,
  };
}

export const normalizeCompanyName = (name: string): string => {
  return name.toLowerCase().replace(/\s/g, '');
};

export const getInsuranceCompanyLogo = (companyName: string): string => {
  const logos: Record<string, string> = {
    agro: '/assets/insuranceCompanies/agrotuw.png',
    allianz: '/assets/insuranceCompanies/allianz.svg',
    balcia: '/assets/insuranceCompanies/balcia.png',
    beesafe: '/assets/insuranceCompanies/beesafe.png',
    benefia: '/assets/insuranceCompanies/benefia.png',
    compensa: '/assets/insuranceCompanies/compensa.png',
    euroins: '/assets/insuranceCompanies/euroins.png',
    generali: '/assets/insuranceCompanies/generali.svg',
    hestia: '/assets/insuranceCompanies/ergo.svg',
    interrisk: '/assets/insuranceCompanies/interrisk.svg',
    link4: '/assets/insuranceCompanies/link4.png',
    mtu: '/assets/insuranceCompanies/mtu.svg',
    mtu24: '/assets/insuranceCompanies/mtu24.png',
    proama: '/assets/insuranceCompanies/proama.svg',
    pzu: '/assets/insuranceCompanies/pzu.png',
    trasti: '/assets/insuranceCompanies/trasti.png',
    tuw: '/assets/insuranceCompanies/tuw.png',
    tuz: '/assets/insuranceCompanies/tuz.svg',
    uniqa: '/assets/insuranceCompanies/uniqa.svg',
    warta: '/assets/insuranceCompanies/warta.png',
    hdi: '/assets/insuranceCompanies/hdi.png',
    wiener: '/assets/insuranceCompanies/wiener.svg',
    gothaer: '/assets/insuranceCompanies/gothaer.png',
    youcandrive: '/assets/insuranceCompanies/youcandrive.png',
  };

  const normalizedName = normalizeCompanyName(companyName);

  // Find the first matching key using `includes`
  const matchedKey = Object.keys(logos).find((key) => normalizedName.includes(key));

  return matchedKey ? logos[matchedKey] : '';
};

export function identifyInsuranceCompanyE(companyName: string): VehicleInsuranceCompany {
  const companyMap: Record<string, VehicleInsuranceCompany> = {
    agro: VehicleInsuranceCompany.AGRO_TUW,
    allianz: VehicleInsuranceCompany.ALLIANZ,
    balcia: VehicleInsuranceCompany.BALCIA,
    beesafe: VehicleInsuranceCompany.BEESAFE,
    benefia: VehicleInsuranceCompany.BENEFIA,
    compensa: VehicleInsuranceCompany.COMPENSA,
    euroins: VehicleInsuranceCompany.EUROINS,
    generali: VehicleInsuranceCompany.GENERALI,
    hestia: VehicleInsuranceCompany.HESTIA,
    ergo: VehicleInsuranceCompany.HESTIA,
    interrisk: VehicleInsuranceCompany.INTERRISK,
    link4: VehicleInsuranceCompany.LINK4,
    mtu24: VehicleInsuranceCompany.MTU24,
    mtu: VehicleInsuranceCompany.MTU,
    proama: VehicleInsuranceCompany.PROAMA,
    pzu: VehicleInsuranceCompany.PZU,
    trasti: VehicleInsuranceCompany.TRASTI,
    tuw: VehicleInsuranceCompany.TUW,
    tuz: VehicleInsuranceCompany.TUZ,
    uniqa: VehicleInsuranceCompany.UNIQA,
    warta: VehicleInsuranceCompany.WARTA,
    hdi: VehicleInsuranceCompany.HDI,
    wiener: VehicleInsuranceCompany.WIENER,
    gothaer: VehicleInsuranceCompany.GOTHAER,
    youcandrive: VehicleInsuranceCompany.YOUCANDRIVE,
  };

  const normalizedName = normalizeCompanyName(companyName);

  // Special case for AGRO_TUW
  if (normalizedName.includes('agro') && normalizedName.includes('tuw')) {
    return VehicleInsuranceCompany.AGRO_TUW;
  }

  // Find the first matching key using `includes`
  const matchedKey = Object.keys(companyMap).find((key) => normalizedName.includes(key));

  return matchedKey ? companyMap[matchedKey] : VehicleInsuranceCompany.UNKNOWN;
}
