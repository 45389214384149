import { create } from 'zustand/react';
import { VehicleInsuranceFormStepE } from '../../containers/InsurancePurchaseForms/VehicleInsurance/vehicleInsuranceEnum';

interface VehicleInsuranceFormState {
  currentStep: VehicleInsuranceFormStepE;
  setCurrentStep: (step: VehicleInsuranceFormStepE) => void;
}

export const useVehicleInsuranceStore = create<VehicleInsuranceFormState>((set) => ({
  currentStep: VehicleInsuranceFormStepE.BASIC_INFO_3_VALUES,
  setCurrentStep: (currentStep: VehicleInsuranceFormStepE) => set({ currentStep }),
}));
