import { Form, Formik, FormikHelpers } from 'formik';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useStore } from '../../store/store';
import MyButton from '../Buttons/MyButton/MyButton';
import * as Yup from 'yup';
import MyTextInput from '../FormControls/MyTextInput/MyTextInput';
import agent from '../../api/agent';
import { IUserJobPositionFormValues, UserAddress, UserInstitution, UserProfileNewModel } from '../../types/user';
import _ from 'lodash';
import RegexHelpers from '../../helpers/RegexHelpers';
import ValidationHelper from '../../helpers/ValidationHelper';
import { AddressPromptingForm } from '../Address/AddressPromptingForm';
import { Button, Container } from 'react-bootstrap';
import InstitutionSelectItem from '../InstitutionSelectItem/InstitutionSelectItem';
import LoadingComponent from '../Loadings/LoadingComponent/LoadingComponent';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InstitutionSearchByAddressForm } from '../Institution/InstitutionSearchByAddressForm';
import MySelectInput from '../FormControls/MySelectInput/MySelectInput';
import { MySelectInputOptions } from '../FormControls/MySelectInput/types';
import { toast } from 'react-toastify';
import { UserJobPosition } from '../../enums/userJobPosition.enums';
import { PositionInTradeUnionOptions, TradeUnionOptions, TradeUnionTypesE } from '../../enums/tradeUnionTypes.enums';
import MyCheckbox from '../FormControls/MyCheckbox/MyCheckbox';

const JobPositionDataValidationSchema = Yup.object().shape({
  sopInstitutionId: Yup.number().required('Należy wskazać placówkę'),
  sopInstitutionName: Yup.string().required('Należy wskazać placówkę'),
  sopInstitutionRegon: Yup.string().required('Należy wskazać placówkę'),
  position: Yup.mixed<UserJobPosition>().required('Stanowisko jest wymagane'),
  isEducationWorker: Yup.boolean().required('Status pracownika oświaty jest wymagany'),
});
const TradeUnionDataValidationSchema = Yup.object().shape({
  tradeUnionId: Yup.number().when('isTradeUnionMember', {
    is: true,
    then: Yup.number().required('Należy wskazać związek zawodowy'),
    otherwise: Yup.number().notRequired(),
  }),
  tradeUnionDocumentNumber: Yup.string().when('isTradeUnionMember', {
    is: true,
    then: Yup.string().required('Należy podać numer dokumentu związkowego'),
    otherwise: Yup.string().notRequired(),
  }),
  positionInTradeUnion: Yup.number().when('tradeUnionId', {
    is: TradeUnionTypesE.Znp,
    then: Yup.number().required('Należy wskazać stanowisko w związku zawodowym'),
    otherwise: Yup.number().notRequired(),
  }),
});

export const UserJobPositionOptions: MySelectInputOptions[] = [
  { value: UserJobPosition.Director, label: 'Dyrektor' },
  { value: UserJobPosition.ViceDirector, label: 'Wicedyrektor' },
  { value: UserJobPosition.Teacher, label: 'Nauczyciel' },
  { value: UserJobPosition.AdministrationEmployee, label: 'Pracownik administracji' },
  { value: UserJobPosition.Other, label: 'Inny' },
];

export interface IJobPositionDataForm {
  onSave?: () => void;
}

const JobPositionDataForm = observer((props: IJobPositionDataForm) => {
  const { userStore } = useStore();
  const [step, setStep] = useState<number>(1);
  const [tradeUnionOptions, setTradeUnionOptions] = useState<MySelectInputOptions[]>(TradeUnionOptions);
  const userTradeUnions = userStore.userTradeUnions;
  const [formValues, setFormValues] = useState<IUserJobPositionFormValues>({
    sopInstitutionId: undefined,
    sopInstitutionName: '',
    sopInstitutionRegon: '',
    position: undefined,
    isTradeUnionMember: false,
    tradeUnionId: undefined,
    tradeUnionDocumentNumber: '',
    isEducationWorker: true,
    positionInTradeUnion: undefined,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const getExistingTradeUnion = async () => {
    let existingTradeUnions = userTradeUnions;
    if (existingTradeUnions?.includes(6)) {
      existingTradeUnions = existingTradeUnions.map((v) => (v === 6 ? 8 : v));
    }
    if (existingTradeUnions && existingTradeUnions.length > 0) {
      const tradeUnionOptions = TradeUnionOptions.filter((x) => existingTradeUnions.includes(Number(x.value)));
      setTradeUnionOptions(tradeUnionOptions);
    }
    if (userTradeUnions.length > 0) {
      setFormValues({ ...formValues, isTradeUnionMember: true });
      if (existingTradeUnions.length == 1) {
        setFormValues({ ...formValues, tradeUnionId: existingTradeUnions[0] });
      }
    }
  };
  const saveUserJobPosition = async (values: IUserJobPositionFormValues) => {
    setLoading(true);
    userStore
      .saveUserJobPosition(values)
      .then((resp) => {
        toast.success('Dane zostały zapisane');
        props.onSave && props.onSave();
      })
      .catch((err) => {
        if (Array.isArray(err)) {
          for (let index = 0; index < err.length; index++) {
            toast.error(err[index]);
          }
        } else {
          toast.error(err);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getExistingTradeUnion();
    setLoading(false);
  }, []);

  return (
    <>
      {step === 1 && (
        <Container
          className='isEducationWorker d-flex flex-column align-items-center justify-content-center'
          style={{ textAlign: 'center', paddingTop: '2em', height: '100vh' }}>
          {loading && <LoadingComponent />}
          <div className='tw-w-50 tw-mx-auto tw-rounded-xl tw-bg-white tw-p-8 tw-text-center tw-shadow-lg'>
            <p className={'tw-mx-auto tw-pb-4 tw-text-center tw-text-xl tw-text-nau-green-dark'}>
              Jesteś pracownikiem oświaty?
            </p>
            <div className='d-flex justify-content-center align-items-center w-100 tw-mt-8 tw-flex-row tw-gap-4'>
              <Button className='btn btn-primary' onClick={() => setStep(2)}>
                <div className='d-flex align-items-center justify-content-center tw-px-2 tw-text-xl tw-font-semibold'>
                  <span>{`TAK`}</span>
                </div>
              </Button>
              <Button className='btn btn-primary' onClick={() => setStep(3)}>
                <div className='d-flex align-items-center justify-content-center tw-px-2 tw-text-xl tw-font-semibold'>
                  <span>{`NIE`}</span>
                </div>
              </Button>
            </div>
          </div>
        </Container>
      )}
      {step === 2 && (
        <Container
          className='isEducationWorker d-flex flex-column align-items-center justify-content-center'
          style={{ textAlign: 'center', paddingTop: '2em', height: '100vh' }}>
          {loading && <LoadingComponent />}
          <div className='tw-w-50 tw-mx-auto tw-rounded-xl tw-bg-white tw-p-8 tw-text-center tw-shadow-lg'>
            <Formik
              enableReinitialize={true}
              initialValues={formValues}
              validationSchema={JobPositionDataValidationSchema}
              onSubmit={async (values, { setSubmitting, setFieldValue }: FormikHelpers<IUserJobPositionFormValues>) => {
                setSubmitting(true);
                setFormValues(values);
                await setStep(3);
                setSubmitting;
              }}>
              {({ isSubmitting, values, setFieldValue }) => (
                <Form className='tw-w-50 sm:tw-px-4'>
                  <div className='tw-mb-4 tw-mt-6 tw-text-center tw-text-xl  tw-text-nau-sea-green'>
                    Uzupełnij swoje dane:
                  </div>
                  <div className='tw-mb-4 tw-flex tw-flex-col tw-gap-4'>
                    <InstitutionSearchByAddressForm
                      cityFormikName={'city'}
                      institutionFullNameFormikName={'sopInstitutionName'}
                      institutionSopIdFormikName={'sopInstitutionId'}
                      postCodeFormikName={'zipCode'}
                      regonFormikName={'sopInstitutionRegon'}
                      streetFormikName={'street'}
                    />
                  </div>
                  <div className=' tw-mb-8 tw-flex tw-flex-col tw-gap-4'>
                    <MySelectInput
                      bodyPortal={true}
                      className='inputNumberHideArrow'
                      name={`position`}
                      options={UserJobPositionOptions}
                      placeholder={'Stanowisko'}
                      value={values.position}
                    />
                  </div>

                  <div className='tw-mt-8 tw-flex tw-flex-col tw-gap-4'>
                    <MyButton
                      className='tw-w-full'
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                      type='submit'
                      variant={'greenDark'}>
                      Dalej
                    </MyButton>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      )}
      {step === 3 && (
        <Container
          className='isEducationWorker d-flex flex-column align-items-center justify-content-center'
          style={{ textAlign: 'center', paddingTop: '2em', height: '100vh' }}>
          {loading && <LoadingComponent />}
          <div className='tw-w-50 tw-mx-auto tw-rounded-xl tw-bg-white tw-p-8 tw-text-center tw-shadow-lg'>
            <Formik
              enableReinitialize={true}
              initialValues={formValues}
              validationSchema={TradeUnionDataValidationSchema}
              onSubmit={async (values, { setSubmitting, setFieldValue }: FormikHelpers<IUserJobPositionFormValues>) => {
                setSubmitting(true);
                await saveUserJobPosition(values);
                setSubmitting(false);
              }}>
              {({ isSubmitting, values, setFieldValue }) => (
                <Form className='tw-w-50 sm:tw-px-4'>
                  <div className='tw-mb-4 tw-mt-6 tw-text-center tw-text-xl  tw-text-nau-sea-green'>
                    <MyCheckbox
                      name='isTradeUnionMember'
                      label='Czy jesteś członkiem związku zawodowego?'
                      value={values.isTradeUnionMember}
                      checked={values.isTradeUnionMember}
                      hidden={userTradeUnions.length > 0}
                      variant='big'
                      onChange={(e) => {
                        setFieldValue('isTradeUnionMember', e.target.checked);
                        setFormValues({ ...values, isTradeUnionMember: e.target.checked });
                      }}
                    />
                  </div>
                  {formValues.isTradeUnionMember && (
                    <>
                      <div className=' tw-mb-8 tw-flex tw-flex-col tw-gap-4'>
                        <p className='tw-text-center tw-text-xl tw-text-nau-sea-green'>
                          Wybierz z poniższej listy związek, do którego należysz
                        </p>
                        <MySelectInput
                          bodyPortal={true}
                          className='inputNumberHideArrow'
                          name={`tradeUnionId`}
                          options={tradeUnionOptions}
                          placeholder={'Związek zawodowy'}
                          value={values.tradeUnionId}
                          onChange={(e) => {
                            setFormValues({ ...values, tradeUnionId: Number(e) });
                          }}
                        />
                      </div>
                      <div className='tw-mb-4 tw-flex tw-flex-col tw-gap-4'>
                        <p className='tw-text-center tw-text-xl tw-text-nau-sea-green'>Podaj nr legitymacji</p>
                        <MyTextInput
                          name='tradeUnionDocumentNumber'
                          placeholder='Numer legitymacji'
                          type='text'
                          value={values.tradeUnionDocumentNumber}
                        />
                      </div>
                      {formValues.tradeUnionId == TradeUnionTypesE.Znp && (
                        <div className=' tw-mb-8 tw-flex tw-flex-col tw-gap-4'>
                          <p className='tw-text-center tw-text-xl tw-text-nau-sea-green'>
                            Wybierz z poniższej listy stanowisko jakie zajmujesz w związku
                          </p>
                          <MySelectInput
                            bodyPortal={true}
                            className='inputNumberHideArrow'
                            name={`positionInTradeUnion`}
                            options={PositionInTradeUnionOptions}
                            placeholder={'Stanowisko w związku'}
                            value={values.positionInTradeUnion}
                          />
                        </div>
                      )}
                    </>
                  )}

                  <div className='tw-mt-8 tw-flex tw-flex-col tw-gap-4'>
                    <MyButton
                      className='tw-w-full'
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                      type='submit'
                      variant={'greenDark'}>
                      Zapisz
                    </MyButton>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      )}
    </>
  );
});
export default JobPositionDataForm;
