import React from 'react';
import { PathRoute } from '../../../constants/pathRoute/Route';
import MyButton from '../../../components/Buttons/MyButton/MyButton';
import { useStore } from '../../../store/store';
import { history } from '../../../index';

const Cashback2VerificationInProgress = () => {
  const { modalStore } = useStore();

  return (
    <div className={'tw-px-2'}>
      <div className='tw-bg-white'>
        <p className='tw-mt-4 tw-text-center tw-text-nau-green-dark'>
          Twoja zgłoszenie jest weryfikowane.
          <br />
          Poinformujemy Cię, gdy tylko proces zostanie zakończony.
        </p>
        <div className={'tw-mt-6 tw-flex tw-justify-center tw-gap-2'}>
          <MyButton onClick={() => history.push(PathRoute.PUSTY)} variant={'primary'} className='tw-w-[50%]'>
            Wróć
          </MyButton>
        </div>
      </div>
    </div>
  );
};

export default Cashback2VerificationInProgress;
