import React from 'react';
import CustomerDashboard from '../../CustomerDashboard/CustomerDashboard';
import * as styles from './styles';
import SubpageHeader from '../../../components/SubpageHeader/SubpageHeader';
import UserPersonalDataLayout from '../../../components/UserPersonalData/UserPersonalDataLayout';
import Cashback2Page from './Cashback2Page';

const Cashback2Layout = () => {
  return (
    <CustomerDashboard>
      <div className='col-md-6 offset-md-3 tw-mb-10' style={styles.container}>
        <SubpageHeader iconHeight={40} iconSrc={'/assets/cashback2/cashback2icon_white.svg'} title={'Skarbonka NAU'} />
        <div className={'sm:tw-px-6 sm:tw-py-6'}>
          <UserPersonalDataLayout>
            <Cashback2Page />
          </UserPersonalDataLayout>
        </div>
      </div>
    </CustomerDashboard>
  );
};

export default Cashback2Layout;
